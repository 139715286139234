import React from 'react'
import '../../styles/Circle.css'

export default function Circle(props) {
  return (
    <div>
      <div
        className='circle'
        style={{
          background: `conic-gradient( #0D95BB ${props.valuepercent}%  , white 1% )`,
          marginRight: '2rem',
        }}
      >
        <div className='innercircle'>
          <p className='value'>{props.value}</p>
        </div>
      </div>
      <p style={{ marginLeft: "20%"}} className='perform'>{props.title}</p>
    </div>
  )
}
