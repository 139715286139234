import React from 'react'
import Products from '../components/all-products/Products'
import Navbar from '../components/Navbar'

export default function ProductPage() {
  return (
    <div>
      <Navbar />
      <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <div style={{height:"70vh", minWidth:"90vw"}}>

      <Products />
        </div>
      </div>
    </div>
  )
}
