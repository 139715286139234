import * as React from 'react'
import { useState } from 'react'
import { Container, Link } from '@material-ui/core'
import { NavAppBar, NavToolBar, NavDrawer } from '../MaterialComponents'
import MenuIcon from '@material-ui/icons/Menu'
import styles from '../../styles/Navbar.module.css'
import { useWindowDimensions } from '../../utils/windowUtils'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Typography } from '@material-ui/core'
import { useLocation } from 'react-router'
import { Modal } from 'react-responsive-modal';

const Navbar3 = (props) => {
	let location = useLocation()

	const [modal, setModal] = useState(false);
	const onOpenModal = () => setModal(true);
	const onCloseModal = () => setModal(false);

	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const { width } = useWindowDimensions()

	const toggleDrawer = (open) => {
		setIsDrawerOpen(open)
	}

	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		event.preventDefault()
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<NavAppBar style={{ height: "8vh", background: "linear-gradient(294.61deg, rgba(47, 85, 175, 0.72) 0.48%, #0C95BA 16.8%, #10054D 101.57%)" }}>
			<Container maxWidth='lg' style={{ margin: "auto" }}>
				<NavToolBar style={{ minHeight: "40px" }} >
					<Link
						href='/'
						style={{
							display: 'flex',
							alignItems: 'center',
							color: '#fff',
							textDecoration: 'none',
						}}
					>
						<span ><img style={{ height: "auto", width: "10vw" }} src="casey-logo-vcheng.png" /></span>
						{/* <img
              src=''
              alt='Logo'
              className={styles.brandLogo}
              style={{
                height: '3rem',
                margin: width <= 768 ? '1.25rem auto' : '0',
              }}
            ></img> */}
					</Link>

					<Typography align="center" style={{ width: "100%", fontSize: "3.5vh" }}>
						{location.state.heading}
					</Typography>

					<Modal open={modal} onClose={onCloseModal} center>
						{location.state.prob}
					</Modal>

					{width >= 992 ? (
						<div
							className={styles.navLink}
							style={{
								display: 'flex',

								height: '100%',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>

							<Link href='' style={{ alignSelf: 'center' }}>
								<img
									src='./user (1).png'
									title="Support"
									alt=''
									style={{ height: '2rem', display: 'block', marginRight: '0' }}
									id='basic-button'
									aria-controls='basic-menu'
									aria-haspopup='true'
									aria-expanded={open ? 'true' : undefined}
									onClick={handleClick}
								></img>
							</Link>
							<Menu
								id='basic-menu'
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									'aria-labelledby': 'basic-button',
								}}
							>
								{/* <MenuItem onClick={ () => {props.setToSpeak(!props.toSpeak);handleClose()}}>
                  <img
                    style={{
                      height: '2rem',
                      display: 'block',
                      paddingTop: '0.5rem',
                      marginRight: '1.5rem',
                      paddingLeft: '1rem',
                    }}
                    src={props.toSpeak?'./sound-1.png':"./mute.png"}
                    alt=''
                  ></img>
                  <div style={{ paddingRight: '2rem' }}>
                  <h4 style={{ marginBottom: '0', marginTop: '0.5rem' }}>
                      TTS
                    </h4>
                  </div>
                </MenuItem> */}
								<MenuItem onClick={handleClose}>
									<div onClick={onOpenModal} style={{ display: "flex", flexDirection: "row" }}>
										<img
											style={{
												height: '2rem',
												display: 'block',
												paddingTop: '0.5rem',
												marginRight: '1.5rem',
												paddingLeft: '1rem',
											}}
											src='./report.png'
											alt=''
										></img>
										<div style={{ paddingRight: '2rem' }}>
											<h4 style={{ marginBottom: '0', marginTop: '0.5rem' }}>
												Problem <br />Statement
											</h4>
										</div>
									</div>
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<img
										style={{
											height: '2rem',
											display: 'block',
											paddingTop: '0.5rem',
											marginRight: '1.5rem',
											paddingLeft: '1rem',
										}}
										src='./customer-support.png'
										alt=''
									></img>
									<div style={{ paddingRight: '2rem' }}>
										<h4 style={{ marginBottom: '0', marginTop: '0.5rem' }}>
											Support
										</h4>
									</div>
								</MenuItem>
							</Menu>
						</div>
					) : (
						<div>
							{/* <img
                src='/assets/svg/menu-bars.svg'
                alt='Hello'
                style={{ color: '#fd5457', height: '1.25rem' }}
                onClick={() => toggleDrawer(true)}
              ></img> */}
							<MenuIcon fontSize='large' onClick={() => toggleDrawer(true)} />
							<NavDrawer
								anchor={'right'}
								open={isDrawerOpen}
								onClose={() => toggleDrawer(false)}
							>
								<div role='presentation' onClick={() => toggleDrawer(false)}>
									<div
										className={styles.navLink}
										style={{
											display: 'block',
										}}
									>
										<Link href=''>Dashboard</Link>
										<Link href=''>Cases</Link>
										{/*<Link href=''>Progress Report</Link>*/}
										<Link href=''>History</Link>
										<Link
											href=''
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<img
												src='./user (1).png'
												title="Support"
												alt=''
												style={{
													height: '2rem',
													display: 'block',
													marginRight: '0',
												}}
												id='basic-button'
												aria-controls='basic-menu'
												aria-haspopup='true'
												aria-expanded={open ? 'true' : undefined}
												onClick={handleClick}
											></img>
										</Link>
										<Menu
											id='basic-menu'
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												'aria-labelledby': 'basic-button',
											}}
										>
											<MenuItem onClick={handleClose}>
												<img
													style={{
														height: '2rem',
														display: 'block',
														paddingTop: '0.5rem',
														marginRight: '1.5rem',
														paddingLeft: '1rem',
													}}
													src='./user (1).png'
													title="Support"
													alt=''
												></img>
												<div style={{ paddingRight: '2rem' }}>
													<h4 style={{ marginBottom: '0' }}>Ayush Jindal</h4>

													<small
														style={{
															color: '#9e8f8e',
															margin: '0',
															fontSize: '0.75rem',
														}}
													>
														IIT Delhi
													</small>
												</div>
											</MenuItem>
											<MenuItem onClick={handleClose}>
												<img
													style={{
														height: '2rem',
														display: 'block',
														paddingTop: '0.5rem',
														marginRight: '1.5rem',
														paddingLeft: '1rem',
													}}
													src='./interview.png'
													alt=''
												></img>
												<div style={{ paddingRight: '2rem' }}>
													<h4 style={{ marginBottom: '0' }}>Interviews </h4>
													<small
														style={{
															color: '#9e8f8e',
															margin: '0',
															fontSize: '0.75rem',
														}}
													>
														tata, gigb
													</small>
												</div>
											</MenuItem>
											<MenuItem onClick={handleClose}>
												<img
													style={{
														height: '2rem',
														display: 'block',
														paddingTop: '0.5rem',
														marginRight: '1.5rem',
														paddingLeft: '1rem',
													}}
													src='./gear.png'
													alt=''
												></img>
												<div style={{ paddingRight: '2rem' }}>
													<h4
														style={{ marginBottom: '0', marginTop: '0.5rem' }}
													>
														Profile Settings
													</h4>
												</div>
											</MenuItem>
											<MenuItem onClick={handleClose}>
												<img
													style={{
														height: '2rem',
														display: 'block',
														paddingTop: '0.5rem',
														marginRight: '1.5rem',
														paddingLeft: '1rem',
													}}
													src='./log-out.png'
													alt=''
												></img>
												<div style={{ paddingRight: '2rem' }}>
													<h4
														style={{ marginBottom: '0', marginTop: '0.5rem' }}
													>
														Log Out
													</h4>
												</div>
											</MenuItem>
										</Menu>
									</div>
								</div>
							</NavDrawer>
						</div>
					)}
				</NavToolBar>
			</Container>
		</NavAppBar>
	)
}

export default Navbar3
