import React from 'react'
import styles from '../../styles/InterviewDetails.module.css'

export default function InterviewDetails(props) {
  const { date, company, icon } = props.detail
  return (
    <div>
      <div className={styles.row}>
        <p>{date}</p>
        <p>{company}</p>

        <img src={icon} style={{ height: '1rem', color: 'red' }} alt=''></img>
      </div>
    </div>
  )
}
