import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import ForgotPasswordPage from "./views/ForgotPasswordPage";
import SignUpPage from "./views/SignUpPage";
import SignInPage from "./views/SignInPage";
import ProfilePage from "./views/ProfilePage";
import CasesPage from "./views/CasesPage";
import HistoryPage from "./views/HistoryPage";
import ChatboxPage from "./views/ChatboxPage";
import DemoBoxPage from "./views/DemoBoxPage";
import DemoPage from "./views/DemoPage";
import LandingPage from "./views/LandingPage";
import DashboardPage from "./views/DashboardPage";
import ReportPage from "./views/ReportPage";
import CasesPage2 from "./views/CasePage2";
import UserPage from "./views/UserPage";
import User from "./components/all-users/User";
import ProductPage from "./views/ProductPage";
import Product from "./components/all-products/Product";
import OrderPage from "./views/OrderPage";
import Order from "./components/all-orders/Order";
import ChatPage from "./views/ChatPage";
import ShowChat from "./components/all-chats/ChatShow";
import SuccessPage from './views/SuccessPage';

const cards = [
  {
    heading: "Oil Marketing Company",
    difficulty: "Easy",
    about: "Profitability",
    icon: "fat.png",
  },
  {
    heading: "Steel Tycoon",
    difficulty: "Easy",
    about: "Profitability",
    icon: "steel.png",
  },
  {
    heading: "Ice Cream Manufacturer",
    difficulty: "Easy",
    about: "Growth Strategy",
    icon: "ice-cream-cone.png",
  },
  {
    heading: "Oil Marketing Company",
    difficulty: "Intermediate",
    about: "Profitability",
    icon: "fat.png",
  },
  {
    heading: "Steel Tycoon",
    difficulty: "Intermediate",
    about: "Profitability",
    icon: "steel.png",
  },
  {
    heading: "Ice Cream Manufacturer",
    difficulty: "Intermediate",
    about: "Growth Strategy",
    icon: "ice-cream-cone.png",
  },
  {
    heading: "Oil Marketing Company",
    difficulty: "Intermediate",
    about: "Profitability",
    icon: "fat.png",
  },
  {
    heading: "Steel Tycoon",
    difficulty: "Intermediate",
    about: "Profitability",
    icon: "steel.png",
  },
  {
    heading: "Ice Cream Manufacturer",
    difficulty: "Intermediate",
    about: "Growth Strategy",
    icon: "ice-cream-cone.png",
  },
];

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/cases">
          <CasesPage cards={cards} />
        </Route>
        <Route exact path="/cases2">
          <CasesPage2 cards={cards} />
        </Route>
        <Route exact path="/history">
          <HistoryPage />
        </Route>
        <Route exact path="/profile">
          <ProfilePage />
        </Route>
        <Route exact path="/signup">
          <SignUpPage />
        </Route>
        <Route exact path="/signin">
          <SignInPage />
        </Route>
        <Route exact path="/forgot-password">
          <ForgotPasswordPage />
        </Route>
        <Route exact path="/chatbox">
          <ChatboxPage />
        </Route>
        <Route exact path="/demobox">
          <DemoBoxPage />
        </Route>
        <Route exact path="/landing/:userId/:caseNo">
          <LandingPage />
        </Route>
        <Route exact path="/demo/:user_id">
          <DemoPage />
        </Route>
        <Route exact path="/dashboard">
          <DashboardPage />
        </Route>
        <Route exact path="/products">
          <ProductPage />
        </Route>
        <Route exact path="/products/:_id">
          <Product />
        </Route>
        <Route exact path="/add-product">
          <Product />
        </Route>
        <Route exact path="/users">
          <UserPage />
        </Route>
        <Route exact path="/users/:_id">
          <User />
        </Route>
        <Route exact path="/add-user">
          <User />
        </Route>
        <Route exact path="/orders">
          <OrderPage />
        </Route>
        <Route exact path="/orders/:_id">
          <Order />
        </Route>
        <Route exact path="/add-order">
          <Order />
        </Route>
        <Route exact path="/chats">
          <ChatPage />
        </Route>
        <Route exact path="/showChat/:id">
          <ShowChat />
        </Route>
        <Route exact path="/report">
          <ReportPage />
        </Route>
        <Route exact path='/success'>
          <SuccessPage />
        </Route>
        <Redirect exact from="/" to="/signin" />
      </Switch>
    </Router>
  );
}
export default App;
