import React, { useState, useContext } from 'react'
import { Typography, Button } from '@material-ui/core'
import axios from '../../../axios'
import { GridProvider, GridContext } from "./dnd/GridContext";
import App1 from "./dnd/App"
import "../../../styles/btn.css"
import { Grid } from '@material-ui/core'
import { Modal } from 'react-responsive-modal';
const Dnd = ({ messages, addToMessageBox, seeQues, setSeeQues, setNextQues, setProgress }) => {
    const { items, moveItem } = useContext(GridContext);
    let map = messages.messages[0].options.map((option, index) => {
        return { "option": option, "index": index }
    })

    const [openImage, setOpenImage] = useState(false);
    const onOpenImageModal = () => setOpenImage(true);
    const onCloseImageModal = () => setOpenImage(false);
    const no = parseInt(messages.messages[0].qtype[3])
    const [options, updateOptions] = useState({ values: map })

    const [confirmation, setConfirmation] = useState(false);
    const onConfirmation = () => setConfirmation(true);
    const onCloseConfirmation = () => setConfirmation(false);

    const onClickConfirm = () => {
        console.log(options)
        let optionsSelected = [], selectedOptions = [];
        for (let x = no - 1; x >= 0; x--) {
            selectedOptions.push({ q: options[x].option, isMyMessage: true })
            optionsSelected.push(options[no - 1 - x].index)
        }

        const url = '/userconvo'
        let getData = {
            message1: messages.messages[0].qid,
            message2: optionsSelected.toString(),
            mode: 0
        }
        sendToChatBox(selectedOptions, selectedOptions.length - 1)
        axios
            .post(url, getData, { headers: { 'Content-Type': 'application/json' } })
            .then((res) => {
                console.log(res)

                let parsedMssg = res.data.msg1[0].split(";;").map((ele, index) => {
                    return ({
                        q: ele,
                        ref: "na"
                    })
                })
                parsedMssg[parsedMssg.length - 1]["qtype"] = res.data.msg1[1]
                parsedMssg[parsedMssg.length - 1]["options"] = res.data.msg1[2]
                parsedMssg[parsedMssg.length - 1]["qid"] = res.data.msg1[3]
                parsedMssg[parsedMssg.length - 1]["exhibit"] = res.data.msg1[4]
                parsedMssg[parsedMssg.length - 1]["progress"] = res.data.msg1[5]

                let respMssg = [{
                    q: res.data.msg2.split(";;")[0],
                    ref: "na"
                }]

                let finalQues = [respMssg, ...selectedOptions]
                // let combinedMssg = [...mssg, respMssg, ...selectedOptions]
                // console.log(combinedMssg)
                // addToMessageBox(finalQues)
                setTimeout(() => { sendToChatBox(respMssg, respMssg.length - 1) }, selectedOptions.length * 1500)
                //setTimeout(function(){addToMessageBox(finalQues)},1500)
                setTimeout(() => { setNextQues(parsedMssg.reverse()) }, (finalQues.length) * 1500)
                setProgress(parseInt(res.data.msg1[5], 10))
            })
            .catch((err) => {
                console.log(err)
            })
    }
    function sendToChatBox(msglist, x) {
        addToMessageBox([msglist[x]])
        if (x > 0)
            setTimeout(() => { sendToChatBox(msglist, x - 1) }, 1500);
    }

    return (
        <div style={{ height: "100%", background: "#0E6495" }}>
            <div style={{ display: seeQues ? "none" : "flex", flexDirection: "column" }}>

                {(messages.messages[0].exhibit == 'na') ? null : <Grid lg={12} style={{ display: confirmation ? "none" : "flex",margin: "auto " }}>
                    <div className="exhibitHover" style={{ cursor: "pointer", width: "100%", display: "flex", justifyContent: "center", margin: "20px 10px 10px" }} onClick={onOpenImageModal}>
                        <img src={messages.messages[0].exhibit} alt=""
                            style={{ minHeight: "100px", minWidth: "100px", maxHeight: "25vh", margin: 'auto 0', borderRadius: 0, border: "1px" }} />
                    </div>
                    <Modal open={openImage} onClose={onCloseImageModal} center classNames={{ modal: 'customModal', closeIcon: 'closeIcon' }}>
                        <img src={messages.messages[0].exhibit} alt="" style={{ minHeight: "100px", minWidth: "100px", margin: "25px", maxHeight: "80vh", maxWidth: "80vw" }} />
                    </Modal>
                </Grid>}
                <Grid lg={12} style={{ display: confirmation ? "flex" : "none",margin: "auto " }}>
                    <div style={{ padding: "10px", cursor: "pointer", width: "100%", display: "flex", justifyContent: "center" }}>
                <p style={{ width: "100%", textAlign: "center", fontSize: "1.5vw", color: "white", fontWeight: "bold" }}>Do you want to confirm the options in the following order? </p>
                </div>
                </Grid>
                {confirmation ?

                    <Grid container spacing={6} className="options" style={{ listStyle: "none", padding: "5vh 10vw", display: "flex", justifyContent: "center" }}>{
                        options.slice(0, no).map((element, index = { index }) => {
                            return (
                                <Grid
                                    key={index}
                                    xs={4}
                                    style={{
                                        margin: "auto 0", display: "flex",
                                        alignItems: "stretch",
                                        width: "25vw",

                                        boxSizing: "border-box"
                                    }}>

                                    <p style={{ display: "flex", fontSize: "2.4vh", color: "#ffffff", margin: "auto" }} >
                                        {index + 1}.
                                    </p>

                                    <li
                                        className={index < no ? "selected" : ""}
                                        style={typoStyles}>
                                        {options[index].option}
                                    </li>

                                </Grid>
                            )
                        }
                        )}
                    </Grid>
                    : <GridProvider options={map} updateOptions={updateOptions} noOfRight={parseInt(messages.messages[0].qtype[3], 10)}>
                        <App1 />
                    </GridProvider>}
            </div>
            <div id="chat-form" style={{
                height: "8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", display: seeQues ? "none" : "flex", justifyContent: "center"
            }}>
                {confirmation ? <div style={{ display: "flex", width: "40%" }}><button className='btn btn__secondary' onClick={onClickConfirm} style={{}} >
                    Confirm
                </button>
                    <button className='btn btn__secondary' onClick={onCloseConfirmation} style={{}}>
                        Back
                    </button> </div> :

                    <button className='btn btn__secondary' onClick={onConfirmation}>
                        Submit
                    </button>}
            </div>
            <div id="chat-form" style={{
                display: seeQues ? "flex" : "none", height: "8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", alignItems: "center", justifyItems:
                    "center"
            }}>
                <button className='btn btn__secondary' onClick={() => { setSeeQues(false) }}>
                    See Options
                </button>
            </div>
        </div>
    )
}
/*const typoStyles = {
    color: "#000",
    fontSize: "1.8vh",
    background: "#dbdbdb",
    padding: "1.5vh",
    margin: "1vh auto",
    borderRadius: "15px",
    lineHeight: "1.2vw",
    userSelect: "none",
    MSUserSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    display: "inline-block",
    width: "65%",
    justifyContent: "center",
    textAlign: "center",
}*/
let typoStyles = {
  color: "#000",
  fontSize: "16px",
  background: "#dbdbdb",
  borderRadius:"12px",
  padding: "12px",
  margin: "10px",
  lineHeight: "1.2vw",
  userSelect: "none",
  cursor: "grabbing",
  width:"65%",
  cursor: "-webkit-grabbing",
  MSUserSelect: "none",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  textAlign: "center"
}


export default Dnd