import React from 'react'
import Circle from './Circle'
import '../../styles/SkillsCharts.css'

export default function SkillsCharts(props) {
  return (
    <div className='skillschart'>
      {/* <p className='heading2' align='center'>
        {props.skill} - {props.method}
      </p> */}
      <div className='grid_second'>
        <Circle value={props.data[1].toFixed(2)} title='MAX' valuepercent= {props.data[1].toFixed(2)*100} />
        <Circle value={props.data[0].toFixed(2)} title='AVG' valuepercent={props.data[0].toFixed(2)*100} />
      </div>
    </div>
  )
}
