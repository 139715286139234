import * as React from "react";
import { useState } from "react";
import { Container, Link } from "@material-ui/core";
import { NavAppBar, NavToolBar, NavDrawer } from "./MaterialComponents";
import MenuIcon from "@material-ui/icons/Menu";
import styles from "../styles/Navbar.module.css";
import { useWindowDimensions } from "../utils/windowUtils";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";

const Navbar = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { width } = useWindowDimensions();

  const toggleDrawer = (open) => {
    setIsDrawerOpen(open);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.preventDefault();
    // setAnchorEl(event.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let history = useHistory();

  const handleLogOut = () => {
    console.log("logout");
    // if (localStorage.getItem('accessToken'))
    //   localStorage.removeItem('accessToken')

    // if (localStorage.getItem('refreshToken'))
    //   localStorage.removeItem('refreshToken')

    history.push("/signIn");
  };

  return (
    <NavAppBar>
      <Container maxWidth="lg">
        <NavToolBar>
          <img
            style={{ height: "auto", width: "10vw", display: "inline-block" }}
            src="casey-logo-vcheng.png"
            alt="case-interview-logo"
          />
          {width >= 992 ? (
            <div
              className={styles.navLink}
              style={{
                display: "flex",

                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link href="/dashboard">Dashboard</Link>
              <Link href="/cases">Cases</Link>
              <Link href="/history">History</Link>
              {/* <Link href="" style={{ alignSelf: "center" }}>
                <img
                  src="./user (1).png"
                  alt=""
                  style={{ height: "2rem", display: "block", marginRight: "0" }}
                  id="basic-button"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                ></img>
              </Link> */}
            </div>
          ) : (
            <>
              <div style={{ marginRight: "1.2rem", display: "inline-block" }}>
                <Link
                  href=""
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="./user (1).png"
                    alt=""
                    style={{
                      height: "2rem",
                      display: "block",
                      marginRight: "0",
                    }}
                    id="basic-button"
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  ></img>
                </Link>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleClose}>
                    <img
                      style={{
                        height: "2rem",
                        display: "block",
                        paddingTop: "0.5rem",
                        marginRight: "1.5rem",
                        paddingLeft: "1rem",
                      }}
                      src="./user (1).png"
                      alt=""
                    ></img>
                    <div style={{ paddingRight: "2rem" }}>
                      <h4 style={{ marginBottom: "0" }}>Ayush Jindal</h4>

                      <small
                        style={{
                          color: "#9e8f8e",
                          margin: "0",
                          fontSize: "0.75rem",
                        }}
                      >
                        IIT Delhi
                      </small>
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <img
                      style={{
                        height: "2rem",
                        display: "block",
                        paddingTop: "0.5rem",
                        marginRight: "1.5rem",
                        paddingLeft: "1rem",
                      }}
                      src="./interview.png"
                      alt=""
                    ></img>
                    <div style={{ paddingRight: "2rem" }}>
                      <h4 style={{ marginBottom: "0" }}>Interviews </h4>
                      <small
                        style={{
                          color: "#9e8f8e",
                          margin: "0",
                          fontSize: "0.75rem",
                        }}
                      >
                        tata, gigb
                      </small>
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <img
                      style={{
                        height: "2rem",
                        display: "block",
                        paddingTop: "0.5rem",
                        marginRight: "1.5rem",
                        paddingLeft: "1rem",
                      }}
                      src="./gear.png"
                      alt=""
                    ></img>
                    <div style={{ paddingRight: "2rem" }}>
                      <h4 style={{ marginBottom: "0", marginTop: "0.5rem" }}>
                        Profile Settings
                      </h4>
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleLogOut}>
                    <img
                      style={{
                        height: "2rem",
                        display: "block",
                        paddingTop: "0.5rem",
                        marginRight: "1.5rem",
                        paddingLeft: "1rem",
                      }}
                      src="./log-out.png"
                      alt=""
                    ></img>
                    <div style={{ paddingRight: "2rem" }}>
                      <h4 style={{ marginBottom: "0", marginTop: "0.5rem" }}>
                        Log Out
                      </h4>
                    </div>
                  </MenuItem>
                </Menu>
              </div>
              <MenuIcon fontSize="large" onClick={() => toggleDrawer(true)} />
              <NavDrawer
                anchor={"right"}
                open={isDrawerOpen}
                onClose={() => toggleDrawer(false)}
              >
                <div role="presentation" onClick={() => toggleDrawer(false)}>
                  <div
                    className={styles.navLink}
                    style={{
                      display: "block",
                    }}
                  >
                    <Link href="">Dashboard</Link>
                    <Link href="">Cases</Link>
                    {/*<Link href=''>Progress Report</Link>*/}
                    <Link href="">History</Link>
                  </div>
                </div>
              </NavDrawer>
            </>
          )}
        </NavToolBar>
      </Container>
    </NavAppBar>
  );
};

export default Navbar;
