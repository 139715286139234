import React, {useState, useEffect, useRef} from 'react'
import Message from './Message'
import '../../../styles/ChatBody1.css'
import "../../../styles/chatboxpage.css"

const ChatBody = ({messages}) => {

  let [globalRef, setGlobalRef] = useState({'na' : useRef(null)})
  //console.log(globalRef)
  console.log(messages.new)
  //console.log(messages1)
    const messageItems = messages.old.messages.length?messages.old.messages.map((message, index) => {
      if(message.q.length !== 0)
        return <Message 
          key={index}
          newMssg={false}
          isMyMessage={message.isMyMessage?message.isMyMessage:false}
          message={message} 
          globalRef={globalRef} setGlobalRef={setGlobalRef}
        />;
      else
        return null
    }): "";

    const messageItems1 = messages.new.messages.length?messages.new.messages.map((message, index) => {
      console.log(message)
      if(message.q.length !== 0)
        return <Message 
          key={index}
          newMssg={true}
          isMyMessage={message.isMyMessage?message.isMyMessage:false}
          message={message} 
          globalRef={globalRef} setGlobalRef={setGlobalRef}
        />;
      else
        return null
    }): "";
    
    return (
    <div id="chat-message-list" className="noScrollbar" style={{height: "77vh", background: "#e5e5e5", padding: "0vh 12vw",  fontSize: "13px",color: "whitesmoke", margin: "0"}} container>
      {messages.new.messages[0].isMyMessage?<div class="stage">
        <div class="dot-pulse"></div>
      </div>:null}
      {messageItems1}
      {messageItems}
    </div>
    )
}

export default ChatBody
