import React, {useState} from 'react';
import classNames from 'classnames';
import { useRef, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import '../../../styles/ChatBody1.css';
const Message = ({ isMyMessage, message, globalRef, setGlobalRef, newMssg }) => {

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const messageClass = classNames('message-row', {
        'you-message': isMyMessage,
        'other-message': !isMyMessage
    });

    const tempRef = useRef()

    if(message.isMyMessage){
        let x = globalRef;
        //console.log(x)
        //onsole.log(message.ref)
        x[message.ref] = tempRef
        //console.log(x)
        setGlobalRef(x)
    }
    
    const imageThumbnail = 
        isMyMessage ? null : <img className="message-row-img" src="bot (1).png" alt={message.imageAlt} />;

    const getCurrentTime = () => {
        var dt = new Date();
        var h =  dt.getHours(), m = dt.getMinutes() > 9 ? dt.getMinutes() : ("0"+dt.getMinutes());
        var ampm = (h>12) ? 'PM' : 'AM';
        h = dt.getHours() > 9 ? dt.getHours() : ("0"+dt.getHours())
        var _time = h+":"+m+ampm;
        return _time
    }

    const ele = () => {
        //console.log(globalRef)
       if(message.isMyMessage) { 
           console.log(message.ref)
            return <div className={messageClass} ref = {globalRef[message.ref]}>
                <div className="message-content">
                    
                    <div className={newMssg?"new":"message-text"}>
                        {message.q}
                    </div>
                    <div className="message-time">{message.time?message.time:getCurrentTime()}</div>
                </div>
            </div>}
        else{
            if(message.ref!='na'){
                return <div className={messageClass} onClick={()=>{
                    globalRef[message.ref].current.scrollIntoView({ behavior: "smooth" })}}>
                    <div className="message-content">
                        
                        <div className={newMssg?"new":"message-text"}>
                        <div className="message-text-parent">
                        {/* <div className="reply-text-left"></div> */}
                            {message.ques}
                        </div>
                            {
                                message.q.split('::').map(function(item, key) {
                                return (
                                <span >
                                    {item.split('***').map(function(item2, key2){
                                        return (<span>{item2.split('**')[0]}<b>{item2.split('**')[1]}</b></span>)
                                        
                                    })}
                                    <br/>
                                </span>
                                )
                                })
                            }
                        </div>
                        <div className="message-time">{message.time?message.time:getCurrentTime()}</div>
                    </div>
                </div>
            }
            else
            return <div>
            <div className={messageClass}>
                <div className="message-content">
                    
                    <div className={newMssg?"new":"message-text"}>
                        {
                            message.q.split('::').map(function(item, key) {
                                return (
                                <span >
                                    {item.split('***').map(function(item2, key2){
                                        return (<span>{item2.split('**')[0]}<b>{item2.split('**')[1]}</b></span>)
                                        
                                    })}
                                    <br/>
                                </span>
                                )
                            })
                        }
                    </div>
                    <div className="message-time">{message.time?message.time:getCurrentTime()}</div>
                </div>
            </div>
            
            {
                (message.exhibit && message.exhibit != 'na')?
                <div>
                    <div className={messageClass}>
                        <div className="message-content">
                            
                            <div className={newMssg?"new-img":"message-text"} style={{padding: "10px", cursor:"pointer"}} onClick={onOpenModal}>
                                <img src={message.exhibit} alt="" style={{minHeight: "100px",minWidth: "100px",maxHeight:"50vh", maxWidth: newMssg?"62vw":"45vw", borderRadius:0, border: "1px"}}/>
                            </div>
                            <Modal  open={open} onClose={onCloseModal} center classNames={{modal: 'customModal', closeIcon: 'closeIcon'}}>
                                <img src={message.exhibit} alt="" style={{minHeight: "100px",minWidth: "100px", margin: "25px",maxHeight: "80vh", maxWidth: "80vw"}}/>
                            </Modal>
                            <div className="message-time">{message.time?message.time:getCurrentTime()}</div>
                        </div>
                    </div>
                </div>:null
            }
            </div>
        }
    }

    useEffect(() => {
        const mssg = ele()
    }, [])

    return (
        <div style={{margin: "1vh 0 0"}}>
            {
               ele()
            }
        </div>
    );
}

const style = {
    background: "#b2dbbf",
    maxHeight: "100vh",
    maxWidth: "100vw"
}
//<img src={require(`../../../assets/exhibits/${message.exhibit}`).default} alt="" style={{minHeight: "100px",minWidth: "100px",maxHeight:"20vh", maxWidth: "25vw", borderRadius:0, border: "1px"}}/>
export default Message;