import React from 'react'
import Chatbox from '../components/demobox/Chatbox'

const ChatboxPage = () => {
  return (
    <div>
      <Chatbox/>
    </div>
  )
}

export default ChatboxPage