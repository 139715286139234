import React from 'react'
import Navbar from '../components/Navbar'
// import Report from '../components/report/ReportHeader'
import '../App.css'
import ReportHeader from '../components/report/ReportHeader'

const ReportPage = (props) => {
  return (
    <div className='App'>
      <Navbar />
      <ReportHeader />
    </div>
  )
}

export default ReportPage
