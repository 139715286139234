import react, { useState, useCallback, useMemo } from 'react';
import { FormGroup, FormControl, InputLabel, Input, Button, Typography } from '@mui/material';
import { useHistory, Link } from 'react-router-dom';
import {useDropzone} from 'react-dropzone'
import { useLocation } from 'react-router';
import axios from '../../axios';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const container = {
      margin: '2% 0 5% 15%',
      '& > *': {
          marginTop: 20
      },
  }
const formControl = {
    width:"90%",
    margin: "4vh 2vw 0"
  }
const input= {
    padding: "0 25px"
  }
const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2%',
    borderRadius: '2%',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer'
  }

  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

const User = () => {
  let location = useLocation()
  const [userDetail, setUserDetail] = useState(
    location.state?.data?{
      ...location.state.data
    }:{
    firstName: "",
    lastName: "",
    email: "",
    institution: "",
    country: "",
    phone: "",
    password: "",
    role: "",
    products: []
  })
    const [file, setFile] = useState('')
    const { firstName, lastName, email, institution, country, phone, password, role, products } = userDetail;
    let history = useHistory();

    const onValueChange = (e) => {
        console.log(e.target.value);
        if(e.target.name === "products"){
        setUserDetail({...userDetail, products: e.target.value.replace(/\s+/g,'').trim().split(",")})
      console.log(userDetail)}
        else
        setUserDetail({...userDetail, [e.target.name]: e.target.value})
    }

    const addUser = async() => {
        // await addUser(user);
        axios.post("/auth/signup", userDetail)
          .then((res) => {
            console.log(res)
            history.push('/users');
          })
          .catch((err) => {
            console.log(err)
          })
    }

    const EditUser = () => {
      axios.put("/user", userDetail)
      .then((res) => {
        console.log(res)
        history.push('/users');
      })
      .catch((err) => {
        console.log(err)
      })
    }
  

    return (
      
    <div style={{background:"#dfdfdf", minHeight:"100vh", minWidth:"100vw", display:"flex", alignItems:"center", justifyContent:"center"}}>
    <div style={{background:"#fff", minHeight:"70vh", width:"80vw", padding:"2vh 2vw", display:"flex", margin:"7vh 0"}}>
      <Link style={{textDecoration: "none"}} to={{pathname:"/users"}}>
      <p><KeyboardBackspaceIcon/>Back</p>
      </Link>
        <FormGroup style={container}>
          <div style={{display:"flex", width:"100%", minWidth:"70vw"}}>
            <div style={{width:"40vw"}}>
            <Typography variant="h4">{location.state?.data?"Edit User":"Add User"}</Typography>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">First Name</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='firstName' value={firstName} id="my-input" />
            </FormControl>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Last Name</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='lastName' value={lastName} id="my-input" />
            </FormControl>
            <FormControl  style={formControl}>
                <InputLabel htmlFor="my-input">Email</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='email' value={email} id="my-input" />
            </FormControl>
            <FormControl  style={formControl}>
                <InputLabel htmlFor="my-input">Role</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='role' value={role} id="my-input" />
            </FormControl>
            <FormControl  style={formControl}>
                <InputLabel htmlFor="my-input">Products</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='products' value={products.join(", ")} id="my-input" />
            </FormControl>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Institution</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='institution' value={institution} id="my-input"/>
            </FormControl>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Country</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='country' value={country} id="my-input" />
            </FormControl>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Phone No.</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='phone' value={phone} id="my-input" />
            </FormControl>
            {location.state?.data?"":<FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Password</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='password' value={password} id="my-input" />
            </FormControl>}<br/>
            <FormControl style={formControl}>
                <Button variant="contained" color="primary" onClick={() => { location.state?.data?EditUser():addUser()}}>{ location.state?.data?"Edit Product":"Add Product"}</Button>
            </FormControl>
            </div>
          </div>
        </FormGroup>
        </div>
        </div>
    )
}

export default User;