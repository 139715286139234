import react, { useState, useCallback, useMemo } from 'react';
import { FormGroup, FormControl, InputLabel, Input, Button, Typography } from '@mui/material';
import { useHistory, Link } from 'react-router-dom';
import {useDropzone} from 'react-dropzone'
import { useLocation } from 'react-router';
import axios from '../../axios';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const container = {
      margin: '2% 0 5% 15%',
      '& > *': {
          marginTop: 20
      },
  }
const formControl = {
    width:"90%",
    margin: "4vh 2vw 0"
  }
const input= {
    padding: "0 25px"
  }
const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2%',
    borderRadius: '2%',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer'
  }

  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

const Product = () => {
  let location = useLocation()
  const [productDetail, setProductDetail] = useState(
    location.state?.data?{
      ...location.state.data
    }:{
    name: "",
    productId: "",
    stripeId: "",
    price: "",
    image: "",
    description: "",
    attributes: {
      type: "",
      level: ""
    },
    problem: "",
  })
    const [file, setFile] = useState('')
    const { name, productId, stripeId, price, description, attributes, problem, image } = productDetail;
    let history = useHistory();

    const onValueChange = (e) => {
        console.log(e.target.value);
        setProductDetail({...productDetail, [e.target.name]: e.target.value})
    }

    const onValueChangeAttribute = (e) => {
      setProductDetail({...productDetail, attributes:{
        ...productDetail.attributes,
        [e.target.name]: e.target.value
      }})
    }

    const addProduct = async() => {
        // await addUser(user);
        axios.post("/product", productDetail)
          .then((res) => {
            console.log(res)
            history.push('/products');
          })
          .catch((err) => {
            console.log(err)
          })
    }

    const EditProduct = () => {
      axios.put("/product", productDetail)
      .then((res) => {
        console.log(res)
        history.push('/products');
      })
      .catch((err) => {
        console.log(err)
      })
    }

    function getBase64(file1) {
      console.log(file1)
      setProductDetail({...productDetail, image: file1})
      var reader = new FileReader();
      var reader2=new FileReader();
      reader.readAsArrayBuffer(file1);
      reader2.readAsDataURL(file1);
      reader.onload = function () {
        //  setImg(reader.result);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
      reader2.onload = function () {
        setProductDetail({ ...productDetail,image: reader2.result})
      };
      reader2.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
    const onDrop = useCallback(acceptedFiles => {
      // Do something with the files
      const files = acceptedFiles.find(f => f)
      console.log(files)
      setProductDetail({ ...productDetail,image: files})
      getBase64(files)
    }, [])
    const {getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject} = useDropzone({accept: 'image/*',onDrop})
  
    const style = useMemo(() => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isDragActive,
      isDragReject,
      isDragAccept
    ]);
  

    return (
      
    <div style={{background:"#dfdfdf", minHeight:"100vh", minWidth:"100vw", display:"flex", alignItems:"center", justifyContent:"center"}}>
    <div style={{background:"#fff", minHeight:"70vh", width:"80vw", padding:"2vh 2vw", display:"flex", margin:"7vh 0"}}>
      <Link style={{textDecoration: "none"}} to={{pathname:"/products"}}>
      <p><KeyboardBackspaceIcon/>Back</p>
      </Link>
        <FormGroup style={container}>
          <div style={{display:"flex", width:"100%", minWidth:"70vw"}}>
            <div style={{width:"40vw"}}>
            <Typography variant="h4">{location.state?.data?"Edit Product":"Add Product"}</Typography>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Name</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='name' value={name} id="my-input" />
            </FormControl>
            <FormControl  style={formControl}>
                <InputLabel htmlFor="my-input">Product Id</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='productId' value={productId} id="my-input" />
            </FormControl>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Stripe Id</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='stripeId' value={stripeId} id="my-input"/>
            </FormControl>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Price</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='price' value={price} id="my-input" />
            </FormControl>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Description</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='description' value={description} id="my-input" />
            </FormControl>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Type</InputLabel>
                <Input style={input} onChange={(e) => onValueChangeAttribute(e)} name='type' value={attributes.type} id="my-input" />
            </FormControl>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Level</InputLabel>
                <Input style={input} onChange={(e) => onValueChangeAttribute(e)} name='level' value={attributes.level} id="my-input" />
            </FormControl>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Problem</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='problem' value={problem} id="my-input" />
            </FormControl><br/>
            <FormControl style={formControl} sty>
                <Button variant="contained" color="primary" onClick={() => { location.state?.data?EditProduct():addProduct()}}>{ location.state?.data?"Edit Product":"Add Product"}</Button>
            </FormControl>
            </div>
          <div style={{margin: "10px 5vw 10px 10px",}}>
            Product Image<br/><br/>
            <div {...getRootProps(style)} style={baseStyle}>
            <img src={productDetail.image} style={{maxWidth: "400px", maxHeight:"50vh"}}/>
              <input {...getInputProps()} />
              {
                isDragActive ?
                <p>Drop the files here ...</p> :
                <p>Drag 'n' drop some files here, or click to select file</p>
              }
            </div>
              </div>
          </div>
        </FormGroup>
        </div>
        </div>
    )
}

export default Product;