import React, { useState } from 'react'
import { Box } from '@mui/system'

export default function Error(props) {
  const [style, setStyle] = useState({ display: 'block' })
  if (!props.errorMsg) return <> </>
  setTimeout(() => {
    setStyle({ display: 'none' })
  }, 3000)
  return (
    <div>
      <Box align='center' mt={2} mb={2} style={style}>
        <h3 style={{ color: 'red' }}> {props.errorMsg}</h3>
      </Box>
    </div>
  )
}
