import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import axios from '../../../axios'

const options = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: false,
        },
      },
    ],
  },
}

export default function LineChart(dataType) {
  const [dataANL, setdataANL] = useState([])
  const [dataSYN, setdataSYN] = useState([])
  const [dataSTR, setdataSTR] = useState([])
  const [dataJDG, setdataJDG] = useState([])
  console.log("hii")
  useEffect(() => {
    axios
      .get('/user-scores')
      .then((res) => {
        console.log("hello")
        console.log(res.data)

        const lineChartData = res.data.filter((object)=>{
          if(object.score) return object
        })
        lineChartData.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
        console.log(lineChartData)
        const ReqANL = {
          labels: lineChartData.map((object, index)=>(index)),
        
          datasets: [
            {
              label: '# of Votes',
              data: lineChartData.map(object=>(object.score["anl"])),
              fill: false,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor: 'rgba(255, 99, 132, 0.2)',
              tension: 0.5,
            },
          ],
        }
        setdataANL(ReqANL);
        const ReqJDG = {
          labels: lineChartData.map((object, index)=>(index)),
        
          datasets: [
            {
              label: '# of Votes',
              data: lineChartData.map(object=>(object.score["jdg"])),
              fill: false,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor: 'rgba(255, 99, 132, 0.2)',
              tension: 0.5,
            },
          ],
        }
        setdataJDG(ReqJDG);
        const ReqSTR = {
          labels: lineChartData.map((object, index)=>(index)),
        
          datasets: [
            {
              label: '# of Votes',
              data: lineChartData.map(object=>(object.score["str"])),
              fill: false,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor: 'rgba(255, 99, 132, 0.2)',
              tension: 0.5,
            },
          ],
        }
        setdataSTR(ReqSTR);
        const ReqSYN = {
          labels: lineChartData.map((object, index)=>(index)),
        
          datasets: [
            {
              label: '# of Votes',
              data: lineChartData.map(object=>(object.score["syn"])),
              fill: false,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor: 'rgba(255, 99, 132, 0.2)',
              tension: 0.5,
            },
          ],
        }
        setdataSYN(ReqSYN);
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  if(dataType.data=="ANL")
  return (
    <div>
      <Line data={dataANL} options={options} height={500} />
    </div>
  )
  else if(dataType.data=="JDG")
  return (
    <div>
      <Line data={dataJDG} options={options} height={500} />
    </div>
  )
  else if(dataType.data=="STR")
  return (
    <div>
      <Line data={dataSTR} options={options} height={500} />
    </div>
  )
  else if(dataType.data=="SYN")
  return (
    <div>
      <Line data={dataSYN} options={options} height={500} />
    </div>
  )
}

