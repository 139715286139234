import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import axios from '../../axios'
import { useHistory } from 'react-router'
import { Button } from '@material-ui/core'
import { Modal } from 'react-responsive-modal';
import { io } from "socket.io-client";
import botlogo from "../../assets/bot.gif"
import ProgressBar from "./Progress"
import ChatBody from './chat_section/ChatBody'
import HelperSection from './HelperSection'
import '../../styles/modal.css'
import useSpeechSynthesis from './useSpeechSynthesis';
import "../../styles/chatboxpage.css"
import Navbar3 from './Navbar3'

var connectionString = "https://www.aspiringconsultants.com"
//var connectionString="http://www.localhost:5000/"
export default function Chatbox(props) {
	let location = useLocation()
	console.log(location.state)
	const [startT, setStartT] = useState(location.state.startT ? (new Date().getTime() - new Date(location.state.startT).getTime()) / 1000 : 0)
	const [modal, setModal] = useState(false);
	const onOpenModal = () => { setModal(true) };
	const onCloseModal = () => setModal(false);
	const [modal2, setModal2] = useState(false);
	const onOpenModal2 = () => { setModal2(true) };
	const onCloseModal2 = () => setModal2(false);
	const [toSpeak, setToSpeak] = useState(false);
	const [playvideo, setplayvideo] = useState(false);
	const videoRef = useRef(null);
	const photoRef = useRef(null);
	let history = useHistory()
	let speakqueue = []
	let [globalRef, setGlobalRef] = useState({ 'na': useRef(null) })

	let quesStatement = {
		q: location.state.prob,
		ref: "na"
	}
	const [messageBox, setMessageBox] = useState({ new: { messages: [quesStatement] }, old: { messages: [] } })
	const [caseState, setCaseState] = useState(false)
	//const [text, setText] = useState("");
	const [nextQues, setNextQues] = useState([])

	const [seeQues, setSeeQues] = useState(true)
	const [progress, setProgress] = useState(0)
	const [exhibits, setExhibits] = useState([])

	const onClickCaseState = () => {
		setCaseState(!caseState);
	}



	let newMessageBox = [...messageBox.new.messages, ...messageBox.old.messages]

	function addToMessageBox(mssg) {
		if (mssg[0].qid && mssg[0].qid === "-1") {
			setCaseState(false)
		}

		console.log(newMessageBox)
		setMessageBox({ new: { messages: mssg }, old: { messages: newMessageBox } })
		let tempExhibits = []
		mssg.forEach(element => {
			if (element?.exhibit) {
				tempExhibits.push(element.exhibit)
			}
		});
		setExhibits([...exhibits, ...tempExhibits])
		newMessageBox = [...mssg, ...newMessageBox];
		if (!mssg[0].isMyMessage) {
			addToSpeakQueue(mssg)
			console.log(speakqueue)
			if (toSpeak && !speaking && speakqueue.length > 0)
				speaktext(speakqueue.shift())
		}
		else {
			speakqueue = []
			clearTimeout(timeoutResumeInfinity)
			cancel()
		}



	}

	function addToSpeakQueue(mssg) {
		let tmp = []
		for (let i = mssg.length - 1; i >= 0; i--) {
			let totalstring = mssg[i].q.split('.  ')
			console.log(totalstring)
			speakqueue.push(...totalstring)

			//tmp=tmp1
			/*for(let k=0;k<totalstring.length;k+=30){
			  let end = k+30>totalstring.length?totalstring.length:k+30;
			  tmp.push(totalstring.slice(k,end).join(' '))
			}*/
			//speakqueue.push(mssg[i].q)
		}
		//console.log(tmp)
		//return tmp
	}
	const onEnd = () => {
		clearTimeout(timeoutResumeInfinity);
		console.log('starting next', speakqueue)
		if (speakqueue.length > 0 && toSpeak)
			speaktext(speakqueue.shift())

	}

	const { speak, cancel, speaking, supported, voices, resume } = useSpeechSynthesis({ onEnd, });
	let timeoutResumeInfinity = null;
	function resumeInfinity() {

		resume();
		timeoutResumeInfinity = setTimeout(resumeInfinity, 1000);
	}
	//const voice = voices[0] || null;
	const pitch = 1;
	const rate = 0.95;
	/* useEffect(()=>{
	   speak({ text,pitch,rate});
	   console.log('has to speak')
	   console.log(speakqueue)
	   //console.log(text,text.length,speaking)
	 },[text]);*/
	function speaktext(text) {
		speak({ text, pitch, rate });
		console.log('has to speak')
		resumeInfinity();
	}
	const addFirstMessage = () => {
		//shutting voice functions
		cancel();
		const url = '/userconvo';
		const getData = {
			user_id: "aa",
			mode: -1,
			message1: location.state.id,
			message2: "z",
		};
		axios.post('/userconvo', JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
			.then((res) => {
				console.log(res.data);
				let parsedMssg = res.data.message[0].split(";;").map((ele, index) => {
					return ({
						q: ele,
						ref: "na"
					})
				})
				parsedMssg[parsedMssg.length - 1]["qtype"] = res.data.message[1]
				parsedMssg[parsedMssg.length - 1]["options"] = res.data.message[2]
				parsedMssg[parsedMssg.length - 1]["qid"] = res.data.message[3]
				parsedMssg[parsedMssg.length - 1]["exhibit"] = res.data.message[4]
				parsedMssg[parsedMssg.length - 1]["progress"] = res.data.message[5]
				addToMessageBox(parsedMssg.reverse())
				setCaseState(true)
				setProgress(parseInt(res.data.message[5], 10))
			})
			.catch((err) => {
				console.log(err)
			})

	}

	var socket;


	/*useEffect(() => {
	  if(playvideo)
	  //getVideo();
	}, [videoRef]);*/

	const [videoOn, setVideoOn] = useState(false)

	const getVideo = () => {
		navigator.mediaDevices
			.getUserMedia({ video: { width: 640 } })
			.then(stream => {
				let video = videoRef.current;
				video.srcObject = stream;
				video.play();
				socket = io(connectionString, { path: "/socket", query: "email=" + location.state.email });
				socket.on('connect', function () {
					console.log('Websocket Connected with App');
					setVideoOn(true)
				});
				socket.on('disconnect', function (evt) { console.log('errr', evt); });
				console.log(socket)
				paintToCanvas();
			})
			.catch(err => {
				console.error("error:", err);
				setVideoOn(false)
			});
	};

	var setIntervalId;
	const paintToCanvas = () => {
		let video = videoRef.current;
		let photo = photoRef.current;
		let ctx = photo.getContext("2d");

		const width = 640;
		const height = 480;
		photo.width = width;
		photo.height = height;

		setIntervalId = setInterval(() => {
			ctx.drawImage(video, 0, 0, width, height);
			if (video.srcObject)
				socket.emit('image', photo.toDataURL());
			else {
				socket.disconnect()
				clearInterval(setIntervalId);
			}
		}, 1000);
	};
	const stop = (e) => {
		setVideoOn(false)
		if (videoRef.current) {
			let video = videoRef.current;
			if (video.srcObject) {

				const stream = video.srcObject;
				const tracks = stream.getTracks();


				for (let i = 0; i < tracks.length; i++) {
					let track = tracks[i];
					track.stop();
				}
				if (socket) {
					socket.disconnect();
				}

				video.srcObject = null;
			}
		}
	}
	useEffect(() => {
		if (location.state.resume) {
			const getData = {
				user_id: "aa",
				mode: 2,
				message1: location.state.id,
				message2: "z",
			};
			axios.post('/userconvo', JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
				.then((res) => {
					console.log(res.data)
					if (res.data.msg2.length > 0) {
						let parsedMssg = res.data.msg2.map((objects) => {
							let obj = objects.ques.split(';;').filter(word => word.length > 0);
							let mmssgg = obj.map((ele) => {
								if (ele != "")
									return ({
										q: ele,
										ref: "na"
									})
							})
							obj = objects.ans.split('::').filter(word => word.length > 0);
							let mmssgg1 = obj.map((ele) => {
								if (ele != "")
									return ({
										q: ele,
										ref: 'na',
										isMyMessage: true
									})
							})
							obj = objects.resp.split(';;').filter(word => word.length > 0);
							let mmssgg2 = obj.map((ele) => {
								if (ele != "")
									return ({
										q: ele,
										ref: 'na'
									})
							})

							return ([...mmssgg, ...mmssgg1, ...mmssgg2])
						})
						let realMssg = []
						for (let i = 0; i < parsedMssg.length; i++) {
							for (let j = 0; j < parsedMssg[i].length; j++)
								realMssg.push(parsedMssg[i][j])
						}
						console.log(realMssg)
						addToMessageBox(realMssg.reverse())
					}
					let parsedMssg1 = res.data.msg1[0].split(";;").map((ele, index) => {
						return ({
							q: ele,
							ref: "na"
						})
					})
					parsedMssg1[parsedMssg1.length - 1]["qtype"] = res.data.msg1[1]
					parsedMssg1[parsedMssg1.length - 1]["options"] = res.data.msg1[2]
					parsedMssg1[parsedMssg1.length - 1]["qid"] = res.data.msg1[3]
					parsedMssg1[parsedMssg1.length - 1]["exhibit"] = res.data.msg1[4]
					parsedMssg1[parsedMssg1.length - 1]["progress"] = res.data.msg1[5]
					addToMessageBox(parsedMssg1.reverse())
					setProgress(parseInt(res.data.msg1[5], 10))
					onOpenModal2()
				})
		}
		else {

			onOpenModal()
		}
	}
		, []);



	/*const [playing, setPlaying] = useState(false);
  
	const toggle = () => setPlaying(!playing);
  
	useEffect(() => {
			playing ? audio.play() : audio.pause();
		},
		[playing]
	);
  
	useEffect(() => {
		audio.addEventListener('ended', () => setPlaying(false));
		return () => {
			audio.removeEventListener('ended', () => setPlaying(false));
		};
	}, []);*/
	return (
		<div className="noScrollbar">
			<Modal open={modal} onClose={onOpenModal} closeIcon={() => { }} center>
				<p className="modal-text">
					Shall we begin?
				</p>
				<div className="button-div">
					<Button className="modal-button" onClick={() => {
						onCloseModal()
						addFirstMessage()
						setCaseState(true)
						setplayvideo(true)
						{/*getVideo()*/ }
					}}>
						Ok
					</Button>
					<Button className="cancel-modal-button" onClick={() => {
						onCloseModal()
						stop()
						history.push('/cases')
					}}>
						Cancel
					</Button>
				</div>
			</Modal>
			<Modal open={modal2} onClose={onOpenModal2} closeIcon={() => { }} center>
				<p className="modal-text">
					Shall we begin?
				</p>
				<div className="button-div">
					<Button className="modal-button" onClick={() => {
						onCloseModal2()
						setCaseState(true)
						setplayvideo(true)
						{/*getVideo()*/ }
					}}>
						Ok
					</Button>
					<Button className="cancel-modal-button" onClick={() => {
						onCloseModal2()
						stop()
						history.push('/cases')
					}}>
						Cancel
					</Button>
				</div>
			</Modal>
			<div style={imgStyles1}>
				<img src={botlogo} style={{ height: "100%" }} />
			</div>
			<Navbar3 />
			<ProgressBar messages={messageBox.old} startT={startT} messages1={messageBox.new} addToMessageBox={addToMessageBox} progress={progress} socket={socket} timerState={caseState} setCaseState={setCaseState} stop={stop} toSpeak={toSpeak} setToSpeak={setToSpeak} />
			<ChatBody messages={messageBox} />
			<HelperSection messages={messageBox.new} addToMessageBox={addToMessageBox} nextQues={nextQues} setNextQues={setNextQues} seeQues={seeQues} setSeeQues={setSeeQues} setCaseState={setCaseState} setProgress={setProgress} stop={stop} />
			<div style={{ position: "fixed", left: "90vw", margin: "0", padding: "0", bottom: "6vh" }}>
				<video ref={videoRef} style={{ height: "20vmin", width: "8vw" }} playsInline muted />
			</div>
			<canvas ref={photoRef} id="canvas" width="320" height="240" style={{ display: "none" }}></canvas>
		</div>
	)
}

const imgStyles1 = {
	position: "absolute", bottom: "12vh", left: "8vw", height: "3vw", width: "3vw"
}

const imgStyles2 = {
	display: "flex", position: "fixed", height: "100vh", alignItems: "center", justifyContent: "center", width: "100vw", zIndex: "2000", background: "black"
}