import React, { useState, useEffect } from 'react'
import axios from '../../axios'
import Loader from '../../utils/Loader'
import { DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton, GridOverlay  } from "@mui/x-data-grid"
import { useHistory } from 'react-router'
import { Link } from "react-router-dom";
import { Button } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, createStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import "./ChatShow.css"
import { Modal } from 'react-responsive-modal';
import "../../styles/scrollbar.css"

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        flexDirection: 'column',
        '& .ant-empty-img-1': {
          fill: '#aeb8c2',
        },
        '& .ant-empty-img-2': {
          fill: '#f5f5f7',
        },
        '& .ant-empty-img-3': {
          fill: '#dce0e6',
        },
        '& .ant-empty-img-4': {
          fill: '#fff',
        },
        '& .ant-empty-img-5': {
          fillOpacity: '0.8',
          fill: '#f5f5f5',
        },
        marginTop:"5px"
      },
      label: {
        margin: "5px 0 2px",
      },
    }),
);


function CustomNoRowsOverlay() {
  const classes = useStyles();

  return (
    <GridOverlay className={classes.root}>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <div className={classes.label}>No Rows</div>
    </GridOverlay>
  );
}

function DownloadCsv() {
  console.log("fff")
  axios.get("csv-all-chats")
    .then(res => {
      console.log(res)
      var blob = new Blob([res.data]);
  if (window.navigator.msSaveOrOpenBlob){
    window.navigator.msSaveBlob(blob, "Casey-Chats.csv");
  }
  else {
    var a = window.document.createElement("a");

    a.href = window.URL.createObjectURL(blob, {
      type: "text/plain"
    });
    a.download = "Casey-Chats.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
      
    }).catch((err) => {console.log(err)})
}

function QuickSearchToolbar(props) {

  return (
    <div className="root" style={{display:"flex", justifyContent:"space-between", padding:"15px 10px 0"}}>
      <div>
        <Button onClick={() => {DownloadCsv()}}><SystemUpdateAltOutlinedIcon /> <span style={{marginLeft:"5px"}}>Download Csv File</span> </Button>
      </div>
      <div>
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </div>
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

function Chats() {

  let history = useHistory()

  const [chats, setChats] = useState([])
  const [rows, setRows] = useState([])
  const [chat, setChat] = useState({})
  const [isLoading, setisLoading] = useState(true)
  const [modal, setModal] = useState(false);
  const onOpenModal = () => setModal(true);
  const onCloseModal = () => setModal(false);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    axios
      .get('/all-chats')
      .then((res) => {
        console.log(res)
        let tempChats = []
        for(let i = 0; i < res.data.length; i++){
          tempChats.push({
            id:res.data[i]._id,
            ...res.data[i]
          })
        }
        setChats(tempChats)
        setRows(tempChats)
        console.log(tempChats)
        setisLoading(false)
      })
      .catch((err) => {
        setisLoading(true)
        console.log(err)
      })
  }, [])

  const [searchText, setSearchText] = useState('');
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = chats.filter((row) => {
      return Object.keys(row).some((field) => {
        // console.log(row[field].toString())
        return searchRegex.test(row[field].toString());
      });
    });
    console.log(filteredRows)
    setRows(filteredRows);
  };

  const handleDeleteClick = (chatId) => {
    setisLoading(true)
    console.log(chatId)
    const newChats = [...chats]
    axios
      .delete(`/chat/${chatId}`)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err.response))
    axios
      .get('/all-chats')
      .then((res) => {
        let tempChats = []
        for(let i = 0; i < res.data.length; i++){
          tempChats.push({
            id:res.data[i]._id,
            ...res.data[i]
          })
        }
        setChats(tempChats)
        setRows(tempChats)
        setisLoading(false)
        // console.log(res.data)
      })
      .catch((err) => {
        setisLoading(true)

        // console.log(err.response)
      })
  }

  const columns = [
    {
      field: "userId",
      headerName: "User Id",
      headerClassName: "tableHeader",
      minWidth: 250,
      flex:1,
      renderCell: (params) => {
        return (
          <>
      <Link style={{textDecoration:"none", width:"100%", color:"black"}} to={{pathname:"/chats/"+params.row.id, state:{data:params.row}}}>
        {params.row.userId}
      </Link>
      </>
      );
    }
    },
    { field: "caseNo", headerName: "Case No", minWidth: 140, flex:0.7, headerClassName: "tableHeader",renderCell: (params) => {
      return (
        <>
    <Link style={{textDecoration:"none", width:"100%", color:"black"}} to={{pathname:"/chats/"+params.row.id, state:{data:params.row}}}>
      {params.row.caseNo}
    </Link>
    </>
    );
  }},
    { field: "problem", headerName: "Problem", minWidth: 140, flex:0.7, headerClassName: "tableHeader",renderCell: (params) => {
      return (
        <>
    <Link style={{textDecoration:"none", width:"100%", color:"black"}} to={{pathname:"/chats/"+params.row.id, state:{data:params.row}}}>
      {params.row.problem}
    </Link>
    </>
    );
  }},
  { field: "analysisScore", headerName: "Analysis Score", minWidth: 120, flex:0.7, headerClassName: "tableHeader",renderCell: (params) => {
    return (
      <>
    <Link style={{textDecoration:"none", width:"100%", color:"black"}} to={{pathname:"/chats/"+params.row.id, state:{data:params.row}}}>
      {params.row.result?.anl}
    </Link>
    </>
    );
  }},
  { field: "judgementScore", headerName: "Judgement Score", minWidth: 120, flex:0.7, headerClassName: "tableHeader",renderCell: (params) => {
    return (
      <>
    <Link style={{textDecoration:"none", width:"100%", color:"black"}} to={{pathname:"/chats/"+params.row.id, state:{data:params.row}}}>
      {params.row.result?.jdg}
    </Link>
    </>
    );
  }},
  { field: "StructuringScore", headerName: "Structuring Score", minWidth: 120, flex:0.7, headerClassName: "tableHeader",renderCell: (params) => {
    return (
      <>
    <Link style={{textDecoration:"none", width:"100%", color:"black"}} to={{pathname:"/chats/"+params.row.id, state:{data:params.row}}}>
      {params.row.result?.str}
    </Link>
    </>
    );
  }},
  { field: "synthesisScore", headerName: "Synthesis Score", minWidth: 120, flex:0.7, headerClassName: "tableHeader",renderCell: (params) => {
    return (
      <>
    <Link style={{textDecoration:"none", width:"100%", color:"black"}} to={{pathname:"/chats/"+params.row.id, state:{data:params.row}}}>
      {params.row.result?.syn}
    </Link>
    </>
    );
  }},
  { field: "result", headerName: "Result", minWidth: 120, flex:0.7, headerClassName: "tableHeader",renderCell: (params) => {
    return (
      <>
    <Link style={{textDecoration:"none", width:"100%", color:"black"}} to={{pathname:"/chats/"+params.row.id, state:{data:params.row}}}>
      {(params.row.result?.anl+params.row.result?.jdg+params.row.result?.str+params.row.result?.syn)/4}
    </Link>
    </>
    );
  }},
  {
    field: "title",
    headerName: "Title",
    headerClassName: "tableHeader",
    minWidth: 160,
    flex:0.8,
    renderCell: (params) => {
      return (
        <>
      <Link style={{textDecoration:"none", width:"100%", color:"black"}} to={{pathname:"/chats/"+params.row.id, state:{data:params.row}}}>
        {params.row.title}
      </Link>
      </>
      );
    }
  },
  {
    field: "status",
    headerName: "Status",
    headerClassName: "tableHeader",
    minWidth: 160,
    flex:0.8,
    renderCell: (params) => {
      return (
        <>
      <Link style={{textDecoration:"none", width:"100%", color:"black"}} to={{pathname:"/chats/"+params.row.id, state:{data:params.row}}}>
        {params.row.status}
      </Link>
      </>
      );
    }
  },
  { field: "chat", headerName: "Chat", minWidth: 120, flex:0.7, headerClassName: "tableHeader",renderCell: (params) => {
    return (
      <>
      <Button className="productView" onClick={() => {setChat(params.row);onOpenModal()}} style={{background:"#0C95BA", color: "#fff", marginRight: "5px", borderRadius: "25px"}}>View</Button>
  </>
  );
  }},
    {
      field: "action",
      headerName: "Action",
      headerClassName: "tableHeader",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button className="productView" onClick={() => {handleDeleteClick(params.row.id)}} style={{background:"red", color: "#fff", borderRadius: "25px"}}>Delete</Button>
          </>
        );
      },
    },
  ];

  return isLoading ? (
    <Loader />
  ) : (
    <>
    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center", marginTop: "2vh"}}>
      <span style={{fontSize: "45px", fontWeight: "600"}}>Chats</span>
    </div>
    <Modal open={modal} onClose={onCloseModal} center  classNames={{modal: 'customModalChats', closeIcon: 'closeIcon'}}>
    <div className="container-team">
      <div>
        <div className="heading-team">
          <div style={{fontSize:"1.8rem", fontWeight:"600"}}>
            {console.log(chat)}
            Chat: {chat?._id}
          </div>
        </div>
        {
          chat?.chat?.length>0?chat?.chat?.map((row) => {
            {console.log(row)}

        return <div className="row-team">
          <div className="row-column-team">
            <b>CID: </b> {row.cid}
          </div>
          <div className="row-column-team">
            <b>Question: </b> {row.ques}
          </div>
          <div className="row-column-team">
            <b>Answer: </b> {row.ans}
          </div>
          <div className="row-column-team">
            <b>Response: </b> {row.resp}
          </div>
          <div className="row-column-team">
            <b>Score: </b> {row.score}
          </div>
          <div className="row-column-team">
            <b>Stype: </b> {row.stype}
          </div>
          <div className="row-column-team">
            <b>Time: </b> {new Date(row.time*1000).toLocaleString()}
          </div>
        </div>
          }) : "No Chat"
        }
      </div>
      
    </div>
  </Modal>
    <DataGrid style={{marginTop: "4vh"}}
        rows={rows}
        components={{
          Toolbar: QuickSearchToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(''),
          },
        }}
        disableSelectionOnClick
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[25, 50, 100]}
        checkboxSelection={true}
      />
      </>
  )
}

export default Chats
