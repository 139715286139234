import React from 'react'
import Navbar from '../components/Navbar'
import Profile from '../components/profile/Profile'
import '../App.css'

const ProfilePage = (props) => {
  return (
    <div className='App'>
      <Navbar />
      <Profile />
    </div>
  )
}

export default ProfilePage
