import React, { useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import styles from '../../../styles/MultiSelect.module.css'
import {options} from "../../../data/conversations"
import { Grid } from '@material-ui/core'
import axios from '../../../axios'
import "../../../styles/btn.css"
import { Modal } from 'react-responsive-modal';
import "../../../styles/modal.css"

const MultiSelect = ({ messages, addToMessageBox, setNextQues, seeQues, setSeeQues, setProgress }) => {

  const [modal, setModal] = useState(false);
  const onOpenModal = () => setModal(true);
  const onCloseModal = () => setModal(false);

  const [openImage, setOpenImage] = useState(false);
  const onOpenImageModal = () => setOpenImage(true);
  const onCloseImageModal = () => setOpenImage(false);

  const [optionCountModal, setOptionCountModal] = useState(false)
  const onOpenOptionCountModal = () => setOptionCountModal(true);
  const onCloseOptionCountModal = () => setOptionCountModal(false);

  const [confirmation, setConfirmation] = useState(false);
  
  const onCloseConfirmation = () => setConfirmation(false);

  let currqtype = messages.messages[0].qtype 
  let maxNo = Number(messages.messages[0].qtype.replace(/\D/g, ''));
  if (maxNo === 0)
    maxNo = Number.MAX_VALUE
  let options = messages.messages[0].options

  const [appState, changeAppState] = useState({  
    activeObjects: new Set(), 
    objects: messages.messages[0].options 
  })

  const onConfirmation = () => {
    let optionsSelected = [];
    let selectedOptions = options.map((option, index) => {  
      if (appState.activeObjects.has(index)) {
        let x = {  
          q: option,
          isMyMessage: true,
          ref: ""
        }
        return x
      }
      else
        return null
    })
    for (let x = selectedOptions.length - 1; x >= 0; x--) 
      if (selectedOptions[x] !== null)   
        optionsSelected.push(selectedOptions[x])

    if (currqtype.includes('max')) {
      if (optionsSelected.length > maxNo || optionsSelected.length == 0) { 
        onOpenModal()
        return null;
      }
    }

    else if ((optionsSelected.length !== maxNo && maxNo !== Number.MAX_VALUE) || optionsSelected.length == 0) { 
      onOpenModal()
      return null;
    }
    setConfirmation(true);

  };

  const onClickConfirm = () => {
    setSeeQues(true)

    let optionsSelected = [];
    let selectedOptions = options.map((option, index) => {  
      if (appState.activeObjects.has(index)) {
        let x = {  
          q: option,
          isMyMessage: true,
          ref: ""
        }
        return x
      }
      else
        return null
    })

    for (let x = selectedOptions.length - 1; x >= 0; x--) 
      if (selectedOptions[x] !== null)   
        optionsSelected.push(selectedOptions[x])
    const url = '/userconvo'
    let getData = {
      message1: messages.messages[0].qid,
      message2: [...appState.activeObjects].sort().join(','),
      mode: 0
    }
    console.log(getData)
    addToMessageBox(optionsSelected)
   axios
    .post(url, getData, {headers: {'Content-Type': 'application/json'}})
    .then((res) => {
      console.log(res.data)
      let parsedMssg = res.data.msg1[0].split(";;").map((ele,index)=>{
        return({
          q: ele,
          ref: "na"
        })
      })
      parsedMssg[parsedMssg.length-1]["qtype"] = res.data.msg1[1]
      parsedMssg[parsedMssg.length-1]["options"] = res.data.msg1[2]
      parsedMssg[parsedMssg.length-1]["qid"] = res.data.msg1[3]
      parsedMssg[parsedMssg.length-1]["exhibit"] = res.data.msg1[4]
      parsedMssg[parsedMssg.length-1]["progress"] = res.data.msg1[5]
      
        let respMssg = {
          q: res.data.msg2,
        }
        let parsedRespMssg = respMssg.q.split(";;").map((ele,index)=>{
          return({
            q: ele,
            ref:"na",
            ques: ""
          })
        })

        let finalQues = [];
        console.log(parsedRespMssg.length,optionsSelected.length,parsedRespMssg)
        if(parsedRespMssg.length>=optionsSelected.length){
          for(let x = 0; x < optionsSelected.length; x++){
            parsedRespMssg[optionsSelected.length-1-x].ques = optionsSelected[x].q
            parsedRespMssg[optionsSelected.length-1-x].ref = `${messages.messages[0].qid}-${optionsSelected.length-1-x}`
            finalQues.push(parsedRespMssg[optionsSelected.length-1-x])
            optionsSelected[x].ref = `${messages.messages[0].qid}-${optionsSelected.length-1-x}`
            finalQues.push(optionsSelected[x])
          }
        }
        
        let combinedMssg = [...parsedRespMssg.reverse(), ...optionsSelected]
        // addToMessageBox(optionsSelected)
        setTimeout(()=>{sendToChatBox(parsedRespMssg,parsedRespMssg.length-1,parsedMssg)},  optionsSelected.length*1500);
        ;
        /*addToMessageBox(optionsSelected)
        setTimeout(function(){addToMessageBox(combinedMssg)},1500)*/
        //setTimeout(()=>{setNextQues(parsedMssg.reverse())},(1+parsedRespMssg.length)*1500)
        setProgress(parseInt(res.data.msg1[5], 10))
        // by context show next question
      })
      .catch((err) => {
        console.log(err)
      })
  }
  function sendToChatBox(msglist, x, ques) {  
    addToMessageBox([msglist[x]])
    if (x > 0)
      setTimeout(() => { sendToChatBox(msglist, x - 1, ques) }, 1500);
    else
      setNextQues(ques.reverse())
  }

  function toggleActive(index) {
    console.log([...appState.activeObjects].join(','));
    if (appState.activeObjects.has(index)) {

      let set = appState.activeObjects  
      set.delete(index)
      changeAppState({ ...appState, activeObjects: set });
      return "";
    }
    else {
      if (appState.activeObjects.size === maxNo && maxNo === 1) { 
        let set = new Set();
        set.add(index)
        changeAppState({ objects: appState.objects, activeObjects: set })
        return "changed option"
      }
      else if (appState.activeObjects.size === maxNo) {  
        onOpenOptionCountModal()
        return null
      }
      let set = appState.activeObjects 
      set.add(index)
      changeAppState({ ...appState, activeObjects: set })
      return "selected"
    }


  }

  useEffect(() => {
    changeAppState({
      activeObjects: new Set(),
      objects: messages.messages[0].options  
    })
  }, [messages])

  return (
    <div style={{ height: "100%", background: "#0E6495" }}>

      <div style={{ display: seeQues ? "none" : "flex", flexDirection: "column" }}>

        

          
          {
            confirmation ? 
            <Grid container spacing={6} className="options" style={{ listStyle: "none", padding: "5vh 10vw", display: "flex", justifyContent:'center'}}>
            <div style={{padding: "10px", cursor: "pointer", width: "100%", display: "flex", flexWrap: "wrap",justifyContent:'center'}}>
            <p style={{ width: "100%", textAlign: "center", fontSize: "1.3vw", color:"white", fontWeight: "bold"}}>Do you want to confirm these options? </p> 
            {appState.objects.map((element, index = { index }) => {
           if(appState.activeObjects.has(index))  { return (
                <Grid
                  key={index}
                  xs={6}
                  style={{ margin: "auto 0" }}
                >
                  <div style={typoStyles} className={appState.activeObjects.has(index) ? styles.selected : ""}>
                    {element}
                  </div>
                </Grid>
              ) } 
              
              else{
                return null;
              }
            })
           }</div> 
           </Grid>
           :
           <Grid container spacing={6} className="options" style={{ listStyle: "none", padding: "5vh 10vw", display: "flex"}}>
           {(messages.messages[0].exhibit== 'na')?null:<Grid lg={12} style={{margin: "auto "}}>
        <div style={{padding: "10px", cursor:"pointer", width:"100%",display: "flex", justifyContent: "center"}} onClick={onOpenImageModal}>
            <img src={messages.messages[0].exhibit} alt="" className="exhibitHover"
          style={{minHeight: "100px",minWidth: "100px",maxHeight:"25vh",margin:'auto 0',borderRadius:0, border: "1px", margin: "20px 10px 10px"}}/>
        </div>
        <Modal  open={openImage} onClose={onCloseImageModal} center classNames={{modal: 'customModal', closeIcon: 'closeIcon'}}>
            <img src={messages.messages[0].exhibit} alt="" style={{minHeight: "100px",minWidth: "100px", margin: "25px",maxHeight: "80vh", maxWidth: "80vw"}}/>
        </Modal>
        </Grid>}
              {appState.objects.map((element, index = { index }) => {
                return (
                  <Grid
                    key={index}
                    xs={6}
                    
                  >
                    <div style={typoStyles} className={appState.activeObjects.has(index) ? styles.selected : ""} onClick={() => { toggleActive(index) }}>
                      {element}
                    </div>
                  </Grid>
                )
              })
            }
            </Grid>
}
        
      </div>


      <div id="chat-form" style={{
        height: "8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", display: seeQues ? "none" : "flex", alignItems: "center", justifyItems:
          "center", justifyContent: "center"
      }}>

        {confirmation ? <div style={{display: "flex", width: "40%"}}><button className='btn btn__secondary' onClick={onClickConfirm}>
          Confirm
        </button> 
        <button className='btn btn__secondary' onClick={onCloseConfirmation}>
          Back
        </button> </div>:
        
          <button className='btn btn__secondary' onClick={onConfirmation}>
           Submit
          </button>}
        <Modal open={modal} onClose={onCloseModal} center>
          <p className="modal-text">
            Kindly Select appropriate Number of Options
          </p>
          <div className="button-div">
            <Button className="modal-button" onClick={onCloseModal}>
              OK
            </Button>
          </div>
        </Modal>
        <Modal open={optionCountModal} onClose={onCloseOptionCountModal} center>
          <p className="modal-text">
            Can not select more than {maxNo}, first deselect an option
          </p>
          <div className="button-div">
            <Button className="modal-button" onClick={onCloseOptionCountModal}>
              OK
            </Button>
          </div>
        </Modal>
      </div>

      <div id="chat-form" style={{
        display: seeQues ? "flex" : "none", height: "8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", alignItems: "center", justifyItems:
          "center"
      }}>
        <button className='btn btn__secondary' onClick={() => { setSeeQues(false) }}>
          See Options
        </button>
      </div>
    </div>
  )
}

let typoStyles = {
  color: "#000",
  fontSize: "16px",
  background: "#dbdbdb",
  borderRadius:"12px",
  padding: "12px",
  margin: "10px",
  lineHeight: "1.2vw",
  userSelect: "none",
  MSUserSelect: "none",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  textAlign: "center"
}

export default MultiSelect