import React from 'react'
import Navbar from '../components/Navbar'
import CaseSelector from '../components/cases/CaseSelector'
import Cases from '../components/cases/Cases'
import '../App.css'

const CasesPage = ({ cards }) => {
	return (
		<div className='App'>
			<Navbar />
			<Cases />
			<CaseSelector cards={cards} />
		</div>
	)
}

export default CasesPage
