import React, {useState} from 'react'
import axios from '../../../axios'
import { Button } from '@material-ui/core';
import { useSpeechRecognition } from 'react-speech-kit';

export default function ChatInput({messages, addToMessageBox, setNextQues, setProgress}) {
  const [newMessage, setNewMessage] = useState("");
  let [micOn, setMicOn] = useState(false)

  //const [val,setVal]=useState('')
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setNewMessage(result);
    },
  });
  const onSendMessage = (message) => {
    var dt = new Date();
    var h =  dt.getHours(), m = dt.getMinutes();
    var _time = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');
    let convoMssg = {
      q: message,
      isMyMessage: true
    }
    // addToMessageBox(convoMssg);
    setNewMessage("");
    
    const url = 'userconvo'
    let getData = {
      message1 : messages.messages[0].qid,
      message2 : message,
      mode: 0
    }
    
    console.log(getData)
    sendToChatBox([convoMssg], 0)
    
      axios
    .post(url, getData, {headers: {'Content-Type': 'application/json'}})
    .then((res) => {
      let parsedMssg = res.data.msg1[0].split(";;").map((ele,index)=>{
        return({
          q: ele,
          ref: "na"
        })
      })
      parsedMssg[parsedMssg.length-1]["qtype"] = res.data.msg1[1]
      parsedMssg[parsedMssg.length-1]["options"] = res.data.msg1[2]
      parsedMssg[parsedMssg.length-1]["qid"] = res.data.msg1[3]
      parsedMssg[parsedMssg.length-1]["exhibit"] = res.data.msg1[4]
      parsedMssg[parsedMssg.length-1]["progress"] = res.data.msg1[5]
      
        let respMssg = {
          q: res.data.msg2
        }
        let parsedRespMssg = respMssg.q.split(";;").map((ele)=>{
          return({
            q: ele,
            ref: "na"
          })
        })
        let finalQues = [...parsedRespMssg, convoMssg]
        // let combinedMssg = [...mssg, ...parsedRespMssg, convoMssg]
        // addToMessageBox(finalQues)
        setTimeout(() => {sendToChatBox(parsedRespMssg.reverse(),parsedRespMssg.length-1)}, 1500)
        //setTimeout(function(){addToMessageBox(finalQues)},1200)
        setTimeout(()=>{setNextQues(parsedMssg.reverse())},(finalQues.length)*1500)
        setProgress(parseInt(res.data.msg1[5], 10))
        console.log(messages)
      })
      .catch((err) => {
        console.log("this is err : ",err)
      })
  }

  function sendToChatBox(msglist,x){
    addToMessageBox([msglist[x]])
    if (x>0)
      setTimeout(() =>{sendToChatBox(msglist,x-1)},1500);
  }

  const onPressEnter =
    (event) => {
      const { key } = event;
      if (key === "Enter") {
        event.preventDefault(); // prevent Enter behaviour
        let trimmedNewMessage = newMessage.trim();
        console.log(trimmedNewMessage)
        if (trimmedNewMessage.length >= 1) {
          onSendMessage(trimmedNewMessage, );
        }
        else {
          return null
        }
      }

    }
    const letslisten=()=>{
      if(listening){
        stop()
        setMicOn(false)
        console.log('stopping')
      }
      else{
        listen()
        setMicOn(true)
        console.log('listening')
      }
    }
    const sendButtonClick = () => {
      let trimmedNewMessage = newMessage.trim();
        console.log(trimmedNewMessage)
        if (trimmedNewMessage.length >= 1) {
          onSendMessage(trimmedNewMessage, );
        }
        else {
          return null
        }
    }
  return (
    <div id="chat-form" style={{height:"8vh", background: "#0F3F79", borderTopRightRadius: "16px", borderTopLeftRadius: "16px", display:"flex", alignItems:"center", justifyItems:
    "center"}}>
      <input type="text" placeholder="Type your message..." style={{ color: "",width: "50vw", height: "3vh", borderRadius:"10px", fontSize: "1.5rem", padding: "5px 1vw", margin:"auto 4vw auto 25vw"}}  value={newMessage} onKeyUp={onPressEnter} onChange={(e) => setNewMessage(e.target.value)} />
      <Button onClick={sendButtonClick} style={{padding: 0, margin: 0, width: "6vw"}}><img src="send.png" style={{height: "3.5vh",margin:"10px 4vw 10px 1vw", display:micOn?"none":""}} /></Button>
      <Button onClick={letslisten} style={{padding: 0, margin: 0, width: "6vw"}}><img src={micOn?"stop.png":"mic (1).png"} style={{height: "3.5vh", margin: "10px 4vw 10px 1vw"}} /></Button>
    </div> 
  )
}