import React from 'react'

import ChatBody from './chat_section/ChatBody'

const ChatSection = (messages, globalRef, setGlobalRef) => {
  return (
    <>
      <ChatBody messages={messages.messages} globalRef={globalRef} setGlobalRef={setGlobalRef} />
    </>
  )
}

export default ChatSection
