import React from "react";
import { Typography, Grid, Container } from "@material-ui/core";
import "../../styles/Cases.css";
import { useWindowDimensions } from "../../utils/windowUtils";
import CardBody from "./CardBody";

export default function Dashboard() {
  const { width } = useWindowDimensions();
  const headingStyle = {
    fontSize: width > 768 ? "5rem" : "3.5rem",
    fontWeight: "bold",
    color: "#fff",
  };

  return (
    <div style={{ paddingBottom: "200px" }}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
          alignItems={"center"}
          justifyContent={"center"}
          className="hero"
        >
          <Grid
            item
            xs={12}
            sm={6}
            align={"center"}
            className="heroTextSection"
          >
            <Typography variant="h1" style={headingStyle}>
              Dashboard
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={6} align='center'>
            <Container>
              <img
                src='goal.png'
                alt=''
                srcset=''
                style={{ width: 300, height: 300 }}
              />
            </Container>
          </Grid> */}
        </Grid>
      </Container>
      {/* <UserDetails /> */}
      <CardBody />
    </div>
  );
}
