import React, {useState, useEffect} from 'react'
import { Button } from '@material-ui/core'
import styles from '../../../styles/MultiSelect.module.css'
// import {options} from "../../../data/conversations"
import { Grid } from '@material-ui/core'
import axios from '../../../axios'
import "../../../styles/btn.css"
import { Modal } from 'react-responsive-modal';
import "../../../styles/modal.css"
import { useLocation } from 'react-router'

const MultiSelect = ({messages, addToMessageBox, setNextQues, seeQues, setSeeQues, setProgress}) => {
  let location=useLocation()
  
  const [modal, setModal] = useState(false);
  const onOpenModal = () => setModal(true);
  const onCloseModal = () => setModal(false);

  const [optionCountModal, setOptionCountModal] = useState(false)
  const onOpenOptionCountModal = () => setOptionCountModal(true);
  const onCloseOptionCountModal = () => setOptionCountModal(false);

  let maxNo = Number(messages.messages[0].qtype.replace(/\D/g, ''));
  if(maxNo === 0)
    maxNo = Number.MAX_VALUE
  let options = messages.messages[0].options

  const [appState, changeAppState] = useState({
    activeObjects: new Set(),
    objects: messages.messages[0].options
  })

  const onClickConfirm = () => {
    

    let optionsSelected = [];
    let selectedOptions = options.map((option, index) => {
      if(appState.activeObjects.has(index))
        {let x = {
          q: option,
          isMyMessage:true,
          ref: ""
        }
        return x
      }
      else
        return null
    })
    for(let x = selectedOptions.length-1; x >= 0; x--)
      if(selectedOptions[x] !== null)
        optionsSelected.push(selectedOptions[x])

    if((optionsSelected.length !== maxNo && maxNo !== Number.MAX_VALUE) || optionsSelected.length==0){
      onOpenModal()
      return null;}

      // addToMessageBox(optionsSelected)
    const url = '/democonvo'
    let getData = {
      message1 : messages.messages[0].qid,
      message2 : [...appState.activeObjects].sort().join(','),
      mode:6,
      user_id:location.state.id
    }
    console.log(getData)
    
    axios
    .post(url, getData, {headers: {'Content-Type': 'application/json'}})
    .then((res) => {
      console.log(res.data)
      let parsedMssg = res.data.msg1[0].split(";;").map((ele,index)=>{
        return({
          q: ele,
          ref: "na"
        })
      })
      parsedMssg[parsedMssg.length-1]["qtype"] = res.data.msg1[1]
      parsedMssg[parsedMssg.length-1]["options"] = res.data.msg1[2]
      parsedMssg[parsedMssg.length-1]["qid"] = res.data.msg1[3]
      parsedMssg[parsedMssg.length-1]["exhibit"] = res.data.msg1[4]
      parsedMssg[parsedMssg.length-1]["progress"] = res.data.msg1[5]
      
        let respMssg = {
          q: res.data.msg2,
        }
        let parsedRespMssg = respMssg.q.split(";;").map((ele,index)=>{
          return({
            q: ele,
            ref: `${messages.messages[0].qid}-${index}`,
            ques: ""
          })
        })

        let finalQues = [];
        parsedRespMssg[parsedRespMssg.length-1].ref ="na"
        for(let x = 0; x < optionsSelected.length; x++){
          parsedRespMssg[optionsSelected.length-1-x].ques = optionsSelected[x].q
          finalQues.push(parsedRespMssg[optionsSelected.length-1-x])
          optionsSelected[x].ref = `${messages.messages[0].qid}-${optionsSelected.length-1-x}`
          finalQues.push(optionsSelected[x])
        }
        
        let combinedMssg = [...parsedRespMssg.reverse(), ...optionsSelected]
        // addToMessageBox(optionsSelected)
        addToMessageBox(optionsSelected)
        setTimeout(()=>{sendToChatBox(parsedRespMssg,parsedRespMssg.length-1)}, 1500);
        ;
        /*addToMessageBox(optionsSelected)
        setTimeout(function(){addToMessageBox(combinedMssg)},1500)*/
        setTimeout(()=>{setNextQues(parsedMssg.reverse())},(optionsSelected.length+parsedMssg.length)*1500)
        setProgress(parseInt(res.data.msg1[5], 10))
        // by context show next question
      })
      .catch((err) => {
        console.log(err)
      })
  }
  function sendToChatBox(msglist,x){
    addToMessageBox([msglist[x]])
    if (x>0)
      setTimeout(() =>{sendToChatBox(msglist,x-1)},1500);
  }

  function toggleActive(index){
    console.log([...appState.activeObjects].join(','));
    if(appState.activeObjects.has(index)){

      let set = appState.activeObjects
      set.delete(index)
      changeAppState({...appState, activeObjects: set});
      return "";
    }
    else {
      if(appState.activeObjects.size === maxNo && maxNo === 1){
        let set = new Set();
        set.add(index)
        changeAppState({objects: appState.objects, activeObjects: set})
        return "changed option"
      }
      else if(appState.activeObjects.size === maxNo){
        onOpenOptionCountModal()
        return null
      }
      let set = appState.activeObjects
      set.add(index)
      changeAppState({...appState, activeObjects: set})
      return "selected"
    }

    
  }

  useEffect(() => {
    changeAppState({
      activeObjects: new Set(),
    objects: messages.messages[0].options
    })
  }, [messages])
  
  return (
    <div style={{height: "100%", background: "#0E6495"}}>

      <div style = {{ display: seeQues?"none":"flex", flexDirection: "column"}}>
        <Grid container spacing={6} className="options" style={{listStyle: "none", padding: "5vh 10vw", display: "flex", justifyContent: "center"}}>
          {
            appState.objects.map((element, index={index})=> {
              return (
                <Grid
                key={index}
                xs={4} 
                style={{margin: "auto 0"}}
                >
                  <div style={typoStyles} className={appState.activeObjects.has(index)?styles.selected:""} onClick={() => {toggleActive(index)}}>
                    {element}
                  </div>
                </Grid>
              )  
            })
          }
        </Grid>
      </div>


      <div id="chat-form" style={{height:"8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", display:seeQues?"none":"flex", alignItems:"center", justifyItems:
    "center"}}>
      <button className='btn btn__secondary' onClick={onClickConfirm}>
        Confirm
      </button>
      <Modal open={modal} onClose={onCloseModal} center>
        <p className="modal-text">
          Kindly Select appropriate Number of Options
        </p>
        <div className="button-div">
          <Button className="modal-button" onClick={onCloseModal}>
            OK
          </Button>
        </div>
      </Modal>
      <Modal open={optionCountModal} onClose={onCloseOptionCountModal} center>
        <p className="modal-text">
          Can not select more than {maxNo}, first deselect an option
        </p>
        <div className="button-div">
          <Button className="modal-button" onClick={onCloseOptionCountModal}>
            OK
          </Button>
        </div>
      </Modal>
    </div> 
    
    <div id="chat-form" style={{ display: seeQues?"flex":"none", height:"8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", alignItems:"center", justifyItems:
    "center"}}>
      <button className='btn btn__secondary' onClick = {() => {setSeeQues(false)}}>
        See Options
      </button>
    </div> 
    </div>
  )
}

let typoStyles = {
  color: "#000",
  fontSize: "1.1vw",
  background: "#dbdbdb",
  padding: "15px",
  margin: "10px 2vw",
  borderRadius: "15px",
  lineHeight: "1.2vw",
  userSelect: "none",
  MSUserSelect: "none",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  textAlign: "center"
}

export default MultiSelect
