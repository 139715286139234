import React from 'react'
import "../../../styles/btn.css"
const Instruction = (props) => {

  const seeOptions = () => {
    props.setSeeQues(true)
  }

  return (
    <div style={{display: props.seeQues?"none":"flex",height:"auto", background:"#0F3F79", flexDirection: 'row',borderRadius: "16px", cursor: "pointer"}} onClick={() => {props.setSeeQues(true)}}>
    <div style={{display:"flex",alignItems:'center',justifyContent: 'center', margin:"auto"}} >
      <span style={{color: "#ffffff", fontSize:"2vh", padding: "auto", margin:"1.2vh 0"}}>
        {
          props.ques.split('::').map(function(item, key) {
            return (
            <span >
                {item}
                <br/>
            </span>
            )
        })
        }
      </span>
    </div>
      <div class="arrow" style={{position:"relative", right:"2vw", margin:"auto 0"}}/>
      </div>
  )
}

export default Instruction
