import React from 'react'
import Navbar from '../components/Navbar'
import History from '../components/History'
import '../App.css'


const HistoryPage = () => {
  return (
    <div className='App'>
      <Navbar/>
      <History />
    </div>
  )
}

export default HistoryPage
