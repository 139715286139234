import { Paper, Link } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import styles from '../../styles/ResetForm.module.css'
import { PrimaryButton, PrimaryInput } from '../MaterialComponents'
import React, { useEffect, useState } from 'react'
import axios from '../../axios'
import { validateResetInput } from '../../utils/validation/validateUtils'
import Loader from '../../utils/Loader'

function ResetForm() {
  const labelStyle = {
    color: '#555',
    fontWeight: '600',
    marginBottom: '0.5rem',
  }

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [college, setCollege] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [id, setId] = useState('')

  useEffect(() => {
    axios
      .get('/user')
      .then((res) => {
        console.log(res.data._id)
        setFirstName(res.data.firstName)
        setLastName(res.data.lastName)
        setCollege(res.data.institution)
        setPhone(res.data.phone)
        setId(res.data._id)
        // setEmail(res.data.email)
        // setInstitution(res.data.institution)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }, [])

  const handleReset = (e) => {
    e.preventDefault()
    setDisabled(false)
  }

  const handleUpdate = (e) => {
    setIsLoading(true)
    e.preventDefault()
    setErrors({})
    const userData = {
      firstName,
      lastName,
      password,
      institution: college,
      phone,
    }

    const errorResult = validateResetInput(userData)

    if (!errorResult.isValid) {
      setDisabled(true)
      setErrors(errorResult.errors)
      return
    }

    axios
      .put(
        `/update-user`,
        {
          firstName,
        },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((res) => {
        setDisabled(true)
        setIsLoading(false)
        console.log('Success')
        console.log(res.data)
      })
      .catch((err) => {
        setIsLoading(false)
        setDisabled(true)
        console.log('Error')
        console.log(err)
      })
  }

  return (
    <div style={{ marginTop: '5rem' }}>
      <Paper elevation={10} className={styles.paper}>
        <div style={{ marginLeft: '2rem' }}>
          <p style={labelStyle} align='left'>
            Name
          </p>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <PrimaryInput
                error
                placeholder='First name'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                disabled={disabled}
                helperText={errors.firstName}
              ></PrimaryInput>
            </Grid>
            <Grid item xs={6}>
              <PrimaryInput
                error
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder='Last name'
                disabled={disabled}
                helperText={errors.lastName}
              ></PrimaryInput>
            </Grid>
          </Grid>

          <p style={labelStyle}>College/Company</p>
          <PrimaryInput
            error
            value={college}
            onChange={(e) => setCollege(e.target.value)}
            placeholder='IIT Delhi'
            disabled={disabled}
            helperText={errors.institution}
          ></PrimaryInput>

          <p style={labelStyle}>Phone Number</p>
          <PrimaryInput
            error
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder='9999121221'
            type='tel'
            disabled={disabled}
            helperText={errors.phone}
          ></PrimaryInput>

          <p style={labelStyle}>Password</p>
          <PrimaryInput
            error
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='********'
            type='password'
            disabled={disabled}
            helperText={errors.password}
          ></PrimaryInput>

          <div
            style={{
              marginTop: '2rem',
              paddingBottom: '2rem',
            }}
            align='center'
          >
            <PrimaryButton style={{ width: '90%' }} onClick={handleReset}>
              Edit Changes
            </PrimaryButton>
            {!disabled && (
              <PrimaryButton
                style={{
                  width: '90%',
                  marginTop: '1.2rem',
                  backgroundColor: 'rgb(40, 166, 72)',
                  border: 'none',
                }}
                onClick={(e) => handleUpdate(e)}
                type='submit'
              >
                Submit Changes
              </PrimaryButton>
            )}
            {isLoading && <Loader />}
          </div>
        </div>
      </Paper>
    </div>
  )
}

export default ResetForm
