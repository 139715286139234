import React from 'react'
import Spreadsheet from "react-spreadsheet";
import { useEffect,useState,createRef } from "react"
import { useScreenshot } from "use-react-screenshot";

const ExcelSheet = ({addToMessageBox,seeQues,setSeeQues}) => {
  // const [opt,setOpt]=useState(true)
  const ref = createRef(null);
  const [image, takeScreenShot] = useScreenshot();
  const [modal, setModal] = useState(false)
  const [save, setSave] = useState(false)
  const [check, setCheck] = useState(false)
  const [finalData, setFinalData] = useState(null)
  const [data, setData] = useState([
    [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
    [{ value: "USD m unless otherwise indicated " }, { value: "Y1" }, { value: "Y2" }, { value: "Y3" }, { value: "Y4" }, { value: "Y4" }, { value: "Y1-Y4 CAGR" }],
    [{ value: "Revenue" }, { value: "" }, { value: "9.20%" }, { value: "9.40%" }, { value: "9.70%" }, { value: "" }, { value: "9.40%" }],
    [{ value: "Direct Manufacturing" }, { value: "45.8" }, { value: "50.1" }, { value: "54.9" }, { value: "60.9" }, { value: "956.6" }, { value: "" }],
    [{ value: "Gross margin" }, { value: "57.40%" }, { value: "57.20%" }, { value: "57.70%" }, { value: "57.50%" }, { value: "57.20%" }, { value: "" }],
    [{ value: "sales/commisiions" }, { value: "10.2" }, { value: "10.9" }, { value: "11.7" }, { value: "13" }, { value: "208" }, { value: "8.60%" }],
    [{ value: "Marketing and Advertising" }, { value: "8.6" }, { value: "9.4" }, { value: "10.5" }, { value: "11.1" }, { value: "174" }, { value: "9.1%" }],
    [{ value: "R&D" }, { value: "8.3" }, { value: "9.3" }, { value: "10.9" }, { value: "13" }, { value: "118" }, { value: "9.5%" }],
    [{ value: "Legal" }, { value: "2.5" }, { value: "2.9" }, { value: "3.2" }, { value: "3" }, { value: "47" }, { value: "8.9%" }],
    [{ value: "Real estate" }, { value: "6.2" }, { value: "7.2" }, { value: "7.9" }, { value: "9" }, { value: "116" }, { value: "8.4%" }],
    [{ value: "General Overhead (incl Admin staff ,etc" }, { value: "10.8" }, { value: "11.4" }, { value: "11.8" }, { value: "13" }, { value: "192" }, { value: "5.2%" }],
    [{ value: "EBITDA" }, { value: "14.6" }, { value: "16.6" }, { value: "17.7" }, { value: "20" }, { value: "422" }, { value: "" }],
    [{ value: "EBITDA" }, { value: "13.60%" }, { value: "14.10%" }, { value: "13.80%" }, { value: "14.10%" }, { value: "18.90%" }, { value: "" }],

  ]);
  // console.log(data)

  const getImage = () => takeScreenShot(ref.current);
  useEffect(()=>{
    console.log(data)
  },[data])

  const seeExcel = ()=>{
    setModal(true); 
    setSeeQues(false)
    setSave(true)
  }

  const saveExcel = ()=>{
    setCheck(true) 
    setFinalData(data)
    setSave(false)
  }

  const goBack = ()=>{
    setCheck(false)
    setSave(true)
  }

  const confirmation = ()=>{

    getImage()
    setCheck(false)
    setSeeQues(true)
    setModal(false)
    setFinalData(data)
    // addToMessageBox(image)
    // console.log(image)
  }

  return (
    <div>
      {
        modal ? 
        <div ref={ref} style={{padding:"2%",marginLeft:"10%"}} >
          {
              !check ? 
              <Spreadsheet data={data} onChange={setData} /> :
              <Spreadsheet data={finalData}  />
          }
               
        </div> :
         ""
      }
      
      <div id="chat-form" style={{
        display: seeQues ? "flex" : "none", height: "8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", alignItems: "center", justifyItems:
          "center"
      }}>
        <button className='btn btn__secondary' onClick={() => { seeExcel() }} >
          See Options
        </button>
      </div>

      <div id="chat-form" style={{
        display: save ? "flex" : "none", height: "8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", alignItems: "center", justifyItems:
          "center"
      }}>
        <button className='btn btn__secondary' onClick={()=>{ saveExcel() }} >
          Save
        </button>
      </div>

      <div id="chat-form" style={{
        display: check ? "flex" : "none", height: "8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", alignItems: "center", justifyItems:
          "center"
      }}>
        <button className='btn btn__secondary me-3' onClick={confirmation}>
          Confirm
        </button>
        <button className='btn btn__secondary' onClick={goBack} >
          Go Back
        </button>
      </div>
      {/* <img  src={image} alt={"ScreenShot"} /> */}
    </div>

    )}
export default ExcelSheet;