import React, {useState} from 'react'
import { Typography, Container } from '@material-ui/core'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { useWindowDimensions } from '../../utils/windowUtils';
import axios from "../../axios"
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core';
import Modal from 'react-responsive-modal';
import '../../styles/modal.css'

const ProgressBar = ({progress, startT,timerState, setCaseState, messages, addToMessageBox, setIntervalId, stop, toSpeak, setToSpeak}) => {

  
  const [modal, setModal] = useState(false);
  const onOpenModal = () => {setModal(true)};
  const onCloseModal = () => setModal(false);
  
  const [completedChatModal, setCompletedChatModal] = useState(false);
  const onOpenCompletedChatModal = () => {
    stop()
    setCompletedChatModal(true)};
  const onCloseCompletedChatModal = () => {
    setCompletedChatModal(false)
    const postData = {
      mode: 3,
      message1: '',
      message2: ''
    }

    axios
      .post(url, postData)
      .then((res) => {
        console.log(res)
        // redirect to dashboard page
        setCaseState(false)
        history.push('/dashboard')
      })
      .catch((err) => {
        console.log(err)
      })
  };

  let history = useHistory();
  const url = '/userconvo'
    const Parentdiv = {
        height: "2vh",
        width: '100%',
        backgroundColor: 'whitesmoke',
        borderRadius: 40,
        dispaly: "flex",
        margin: "auto 2vw auto 10vw",
        width: "35vw"
      }
      
      const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: "#3AB9D6",
       borderRadius:40,
        textAlign: 'right'
      }
      
      const progresstext = {
        display: "flex",
        flexDirection: "column-reverse",
        padding: "0 10px",
        color: 'black',
        fontSize: "1.5vh",
        fontWeight: "bold"
      }

      const renderTime = ({ remainingTime }) => {
        var timerhours = Math.floor(remainingTime / 3600);
        var timerminutes = -timerhours * 60 + Math.floor(remainingTime / 60);
        var timerseconds = -timerminutes * 60 - timerhours * 3600 + remainingTime;
        if (remainingTime === 0) {
          timerminutes = 0;
          timerseconds = 0;
        }
        return (
          <div className="timer" style={{position:"absolute",left:"-6vw",fontSize: "2vw", display:"flex", color: "#131B23", fontWeight: "bold", margin: "auto"}}>
            <div className="minutesvalue">{timerminutes<10?`0${timerminutes}`:timerminutes}:</div>
            <div className="secondsvalue">{timerseconds<10?`0${timerseconds}`:timerseconds}</div>
          </div>
        );
      };

      const onClickHint = (e) => {
        const getData = {
          mode: 1,
          message1: messages.messages[0].qid,
          message2: '',
          user_id : "a"
        }
        
    
        axios
          .post(url, getData)
          .then((res) => {
            let mssg = [{
              q: res.data.message[0],
              qtype: res.data.message[1],
              options: res.data.message[2],
              qid: res.data.message[3]
            }]
            addToMessageBox(mssg)
            console.log(mssg)
            // add message to message context
          })
          .catch((err) => {
            console.log(err)
          })
      }


    return (
        <div style={{height: "7vh", background: "#ffffff", boxShadow: "0px 4px 28px -10px rgba(0, 0, 0, 0.25)", display: "flex", margin:"auto", alignItems: "center", justifyItems: "center"}}>
            <div style={{display: "flex", margin: "auto", flexDirection:"column"}}>
              <Modal open={modal} onClose={onCloseModal} center>
                <p className="modal-text">
                  Do you want to End the Case
                </p>
                <div className="button-div">
                  <Button className="modal-button" onClick={()=>{
                    stop()
                    onCloseModal()
                    const postData = {
                      mode: 3,
                      message1: '',
                      message2: ''
                    }
                
                    axios
                      .post(url, postData)
                      .then((res) => {
                        console.log(res)
                        history.push('/success')
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                      setCaseState(false)
                  }}>
                    Ok
                  </Button>
                  <Button className="cancel-modal-button" onClick={() =>{
                    onCloseModal()
                  }}>
                    Cancel
                  </Button>
                </div>
              </Modal>
              <img src="poff2.png" title="End Chat" style={{height:"3.5vh", width:"3.5vh", margin:"auto"}} onClick={onOpenModal}/>
              <div style={{fontWeight:"600", fontSize: "1.75vh"}}>
              End Chat
              </div>
            </div>
            <div style={Parentdiv}>
                <div style={Childdiv}>
                    <span style={progresstext}>{`${progress}%`}</span>
                </div>
            </div>
            <div style={{ display: "flex", padding: "0px 10px", margin: "auto 10vw auto 0", width: "10vw", flexDirection:"start"}}>
                    <Typography aling="center" style={{fontSize: "1.2vw",color:"#131B23", padding:"10px", fontWeight:"bold"}}>
                    Time Left
                    </Typography>
                <div style={{position: "relative", left: "7vw", display: "flex", margin:"auto" }}>

                <Modal open={completedChatModal} onClose={onCloseCompletedChatModal} center>
                <p className="modal-text">
                  Test is Over click OK to leave the Chat Window
                </p>
                <div className="button-div">
                  <Button className="modal-button" onClick={()=>{
                    onCloseCompletedChatModal()
                  }}>
                    Ok
                  </Button>
                </div>
              </Modal>

                <CountdownCircleTimer
                    // key = {key}
                    isPlaying={timerState}
                    duration = {30 * 60}
                    colors={[
                    ['#42B6D6', 1]
                    ]}
                    strokeWidth = {useWindowDimensions().height/200}
                    size = {useWindowDimensions().height/25}
                    initialRemainingTime={30 * 60 - startT}
                    trailColor = "#C4C4C4"
                    onComplete = {onOpenCompletedChatModal}
                    style={{margin: "auto"}}
                >
                    {renderTime}
                </CountdownCircleTimer>
                </div>
            </div>
            <div style={{display: "flex", margin: "auto"}}  onClick={ () => {setToSpeak(!toSpeak);}}>
              <img
                style={{
                  height: '4vh',
                }}
                src={toSpeak?'./sound-1.png':"./mute.png"}
                title={toSpeak?"Mute":"Unmute"}
                alt=''
              ></img>
            </div>
        </div>
    )
}

export default ProgressBar
