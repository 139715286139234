import { Paper } from '@material-ui/core'
import styles from '../../styles/Interviews.module.css'
import React from 'react'
import { Button } from '@material-ui/core'
import InterviewDetails from './InterviewDetails'

export default function Interviews() {
  const details = [
    {
      date: '2021-09-09',
      company: 'tata',
      icon: 'garbage.png',
    },
    {
      date: '2021-09-21',
      company: 'gigb',
      icon: 'garbage.png',
    },
  ]
  return (
    <div style={{ paddingBottom: '200px' }}>
      <Paper elevation={10} className={styles.paper}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h1>Interview</h1>
          <Button
            style={{
              backgroundColor: '#28a648',
              color: 'white',
              fontSize: '0.75rem',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
            variant='contained'
            startIcon={
              <img src='./add.png' style={{ height: '1rem' }} alt=''></img>
            }
          >
            Add Interview
          </Button>
        </div>
        <div className={styles.tableHead}>
          <p>Date</p>
          <p style={{ width: '0.9rem' }}>Company</p>
          <img
            src='.garbage.png'
            style={{ height: '1rem', opacity: '0' }}
            alt=''
          ></img>
        </div>
        {details.map((detail) => (
          <InterviewDetails
            detail={detail}
            key={detail.date}
          ></InterviewDetails>
        ))}
      </Paper>
    </div>
  )
}
