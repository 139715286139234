import React from 'react'

export default function Loader() {
  return (
    <div>
      <div align='center' style={{ margin: '1rem' }}>
        <img src='loading.gif' alt='' height='50px'></img>
      </div>
    </div>
  )
}
