import react, { useState, useCallback, useMemo } from 'react';
import { FormGroup, FormControl, InputLabel, Input, Button, Typography } from '@mui/material';
import { useHistory, Link } from 'react-router-dom';
import {useDropzone} from 'react-dropzone'
import { useLocation } from 'react-router';
import axios from '../../axios';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const container = {
      margin: '2% 0 5% 15%',
      '& > *': {
          marginTop: 20
      },
  }
const formControl = {
    width:"90%",
    margin: "4vh 2vw 0"
  }
const input= {
    padding: "0 25px"
  }
const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2%',
    borderRadius: '2%',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer'
  }

  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

const Order = () => {
  let location = useLocation()
  const [orderDetail, setOrderDetail] = useState(
    location.state?.data?{
      ...location.state.data
    }:{
    sessionId: "",
    productId: "",
    platform: "",
    email: "",
    body: ""
  })
    const [file, setFile] = useState('')
    const { sessionId, productId, platform, email, body } = orderDetail;
    let history = useHistory();

    const onValueChange = (e) => {
        console.log(e.target.value);
        setOrderDetail({...orderDetail, [e.target.name]: e.target.value})
    }

    const onValueChangeAttribute = (e) => {
      setOrderDetail({...orderDetail, attributes:{
        ...orderDetail.attributes,
        [e.target.name]: e.target.value
      }})
    }

    const addOrder = async() => {
        // await addUser(user);
        axios.post("/order", orderDetail)
          .then((res) => {
            console.log(res)
            history.push('/orders');
          })
          .catch((err) => {
            console.log(err)
          })
    }

    const EditOrder = () => {
      axios.put("/order", orderDetail)
      .then((res) => {
        console.log(res)
        history.push('/orders');
      })
      .catch((err) => {
        console.log(err)
      })
    }

    return (
      
    <div style={{background:"#dfdfdf", minHeight:"100vh", minWidth:"100vw", display:"flex", alignItems:"center", justifyContent:"center"}}>
    <div style={{background:"#fff", minHeight:"70vh", width:"80vw", padding:"2vh 2vw", display:"flex", margin:"7vh 0"}}>
    <Link style={{textDecoration: "none"}} to={{pathname:"/orders"}}>
      <p style={{display:"flex", alignItems:"center"}}><KeyboardBackspaceIcon/>Back</p>
      </Link>
        <FormGroup style={container}>
          <div style={{display:"flex", width:"100%", minWidth:"70vw"}}>
            <div style={{width:"40vw"}}>
            <Typography variant="h4">Edit Order</Typography>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Session Id</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='sessionId' value={sessionId} id="my-input" readOnly={true} />
            </FormControl>
            <FormControl  style={formControl}>
                <InputLabel htmlFor="my-input">Product Id</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='productId' value={productId} id="my-input" />
            </FormControl>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Platform</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='platform' value={platform} id="my-input"/>
            </FormControl>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">email</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='email' value={email} id="my-input" />
            </FormControl>
            <FormControl style={formControl}>
                <InputLabel htmlFor="my-input">Body</InputLabel>
                <Input style={input} onChange={(e) => onValueChange(e)} name='body' value={JSON.stringify(body)} id="my-input" readOnly={true} multiline={true} />
            </FormControl><br/>
            <FormControl style={formControl} sty>
                <Button variant="contained" color="primary" onClick={() => { location.state?.data?EditOrder():addOrder()}}>{ location.state?.data?"Edit Product":"Add Product"}</Button>
            </FormControl>
            </div>
          </div>
        </FormGroup>
        </div>
        </div>
    )
}

export default Order;