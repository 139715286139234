// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ForgotPassword_paper__2nVJ7 {\n    width: 60vh;\n    margin: 5rem auto;\n    padding: 2rem;\n    background: #fff;\n    border-radius: 30px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n\n}\n\n\n.ForgotPassword_sideImage__3rJtB {\n    position: absolute;\n    right: 8%;\n    bottom: 0;\n    z-index: -100;\n}\n\n@media (max-width: 600px) {\n    .ForgotPassword_sideImage__3rJtB {\n        display: none;\n    }\n\n    .ForgotPassword_paper__2nVJ7 {\n        width: 85vw;\n    }\n}", "",{"version":3,"sources":["webpack://src/styles/ForgotPassword.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,2CAA2C;;AAE/C;;;AAGA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".paper {\n    width: 60vh;\n    margin: 5rem auto;\n    padding: 2rem;\n    background: #fff;\n    border-radius: 30px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n\n}\n\n\n.sideImage {\n    position: absolute;\n    right: 8%;\n    bottom: 0;\n    z-index: -100;\n}\n\n@media (max-width: 600px) {\n    .sideImage {\n        display: none;\n    }\n\n    .paper {\n        width: 85vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "ForgotPassword_paper__2nVJ7",
	"sideImage": "ForgotPassword_sideImage__3rJtB"
};
export default ___CSS_LOADER_EXPORT___;
