export const messages = [
    {
        messageText: 'Ok then',
        createdAt: 'Apr 16',
        isMyMessage: true
    },
    {
        messageText: `
            Yeah I think it's best we do that. Otherwise things won't work well at all. 
            I'm adding more text here to test the sizing of the speech bubble and the 
            wrapping of it too.
        `,
        createdAt: 'Apr 16',
        isMyMessage: false
    },
    {
        messageText: 'Maybe we can use Jim\'s studio.',
        createdAt: 'Apr 15',
        isMyMessage: true
    },
    {
        messageText: `
            All I know is where I live it's too hard
            to record because of all the street noise.
        `,
        createdAt: 'Apr 15',
        isMyMessage: false
    },
    {
        messageText: `
            Well we need to work out sometime soon where
            we really want to record our video course.
        `,
        createdAt: 'Apr 15',
        isMyMessage: true
    },
    {
        messageText: `
            I'm just in the process of finishing off the
            last pieces of material for the course.
        `,
        createdAt: 'Apr 15',
        isMyMessage: false
    },
    {
        messageText: 'How\'s it going?',
        createdAt: 'Apr 13',
        isMyMessage: true
    },
    {
        messageText: ' Hey mate what\'s up?',
        createdAt: 'Apr 13',
        isMyMessage: false
    },
    {
        messageText: 'Hey Daryl?',
        createdAt: 'Apr 13',
        isMyMessage: true
    }
]

export const options = [
        {id:1, data: "ipsum dolor sit amet consectetur adipisicing elit. Error, mollitia repellendus."},
        {id:2, data: "Lorem  dolor sit amet consectetur adipisicing elit. Error, mollitia repellendus."},
        {id:3, data: "Lorem ipsum  sit amet consectetur adipisicing elit. Error, mollitia repellendus."},
        {id:4, data: "Lorem ipsum dolor  amet consectetur adipisicing elit. Error, mollitia repellendus."},
        {id:5, data: "Lorem ipsum dolor sit  consectetur adipisicing elit. Error, mollitia repellendus."},
        {id:6, data: "Lorem ipsum dolor sit amet  adipisicing elit. Error, mollitia repellendus."}
]