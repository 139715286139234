import React from 'react'
import { Typography, Grid, Container } from '@material-ui/core'
import { useWindowDimensions } from '../../utils/windowUtils'
import '../../styles/Profile.css'
import ResetForm from './ResetForm'
import Interviews from './Interviews'

export default function Profile() {
  const { width } = useWindowDimensions()
  const textStyle = {
    fontSize: width > 450 ? '6rem' : '4rem',
    fontWeight: 'bold',
    margin: '0',
  }

  const alignPosition = width > 768 ? 'left' : 'center'

  return (
    <>
      <Container maxWidth='lg'>
        <Grid container spacing={4} className='hero'>
          <Grid
            item
            xs={12}
            sm={6}
            className='TextSection'
            align={alignPosition}
          >
            <div
              style={{
                color: '#ffffff',
                marginLeft: '3rem',
              }}
            >
              <Typography style={textStyle}>My</Typography>
              <Typography style={textStyle}>Profile</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} align='center'>
            <Container>
              <img
                src='goal.png'
                alt=''
                srcset=''
                style={{ width: 300, height: 300 }}
              />
            </Container>
          </Grid>
        </Grid>
      </Container>
      <ResetForm />
      <div style={{ paddingBottom: '2rem' }}></div>
      <Interviews />
    </>
  )
}
