import React, { useContext } from "react";
import DragItem from "./DragItem";
import { Grid, GridItem } from "./Grid";
import GridContext from "./GridContext";
import "../../../../styles/dnd.css"

function App1() {
  const { items, moveItem, updateOptions, noOfRight } = useContext(GridContext);

  let itemsList = []
  const ShowList = () => {
    for(let x = 0; x < items.length; x++){
      itemsList.push(<DragItem key={items[x].index} id={items[x].index} onMoveItem={moveItem}>
        <GridItem>
          <p style={{ display:"flex", fontSize: "2.4vh", color:"#ffffff", margin:"auto"}} >
            {x+1}.
          </p>
          <li
          className={x<noOfRight?"selected":""}
            style={typoStyles}>
              {items[x].option}
          </li>
        </GridItem>
      </DragItem>)
    }
    return itemsList
  }
  ShowList()
  return (
    <div>
      <Grid style={{display: "flex", justifyContent: "center"}}>
        {updateOptions(items)}
        {itemsList}
      </Grid>
    </div>
  );
}

let typoStyles = {
  color: "#000",
  fontSize: "18px",
  background: "#dbdbdb",
  borderRadius:"12px",
  padding: "12px",
  margin: "10px",
  lineHeight: "20px",
  userSelect: "none",
  cursor: "grabbing",
  width:"65%",
  cursor: "-webkit-grabbing",
  MSUserSelect: "none",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  textAlign: "center"
}


export default App1;
