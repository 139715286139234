import React from 'react'
import "./ChatShow.css"
import { useLocation } from 'react-router'
import MuiAlert from '@mui/material/Alert';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {Link} from "react-router-dom"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ShowChat() {
  let location=useLocation()
  const chat = location.state?.data
  console.log(location.state)

  return (
    <div className="container-team">
      <Link style={{textDecoration: "none"}} to={{pathname:"/chats"}}>
      <p style={{fontSize: "1rem", display: "flex", alignItems: "center"}}><KeyboardBackspaceIcon/><span>Back</span></p>
      </Link>
      <div>
        <div className="heading-team">
          <div style={{fontSize:"1.8rem", fontWeight:"600"}}>
            Chat: {chat._id}
          </div>
        </div>
        {
          chat.chat.length>0?chat.chat.map((row) => {
            {console.log(row)
              console.log(new Date(parseFloat(row.time)))}

        return <div className="row-team">
          <div className="row-column-team">
            <b>CID: </b> {row.cid}
          </div>
          <div className="row-column-team">
            <b>Question: </b> {row.ques}
          </div>
          <div className="row-column-team">
            <b>Answer: </b> {row.ans}
          </div>
          <div className="row-column-team">
            <b>Response: </b> {row.resp}
          </div>
          <div className="row-column-team">
            <b>Score: </b> {row.score}
          </div>
          <div className="row-column-team">
            <b>Stype: </b> {row.stype}
          </div>
          <div className="row-column-team">
            <b>Time: </b> {new Date(row.time)}
          </div>
        </div>
          }) : "No Chat"
        }
      </div>
      
    </div>
  )
}
