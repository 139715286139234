import React from 'react'
import Orders from '../components/all-orders/Orders'
import Navbar from '../components/Navbar'

export default function OrderPage() {
  return (
    <div>
      <Navbar />
      <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <div style={{height:"70vh", minWidth:"90vw"}}>

      <Orders />
        </div>
      </div>
    </div>
  )
}
