import React, {useState, useContext} from 'react'
import { Typography, Button } from '@material-ui/core'
import axios from '../../../axios'
import { GridProvider, GridContext } from "./dnd/GridContext";
import App1 from "./dnd/App"
import "../../../styles/btn.css"
import { useLocation } from 'react-router'

const Dnd = ({messages, addToMessageBox, seeQues, setSeeQues, setNextQues, setProgress}) => {
  const { items, moveItem } = useContext(GridContext);
  let location=useLocation()
  
  let map = messages.messages[0].options.map((option, index) => {
    return {"option": option,"index": index}
  })
  
  const no = parseInt(messages.messages[0].qtype[3])
  const [options, updateOptions] = useState({values: map})

  const onClickConfirm = () => {
    console.log(options)
    let optionsSelected = [], selectedOptions = [];
    for(let x = no-1; x >= 0; x--){
      selectedOptions.push({q:options[x].option,isMyMessage:true})
      optionsSelected.push(options[no-1-x].index)
    }

    const url = '/democonvo'
    let getData = {
      message1 : messages.messages[0].qid,
      message2 : optionsSelected.toString(),
      mode: 6,
      user_id:location.state.id
    }
    
    axios
    .post(url, getData, {headers: {'Content-Type': 'application/json'}})
    .then((res) => {
        console.log(res)
        
        let parsedMssg = res.data.msg1[0].split(";;").map((ele,index)=>{
          return({
            q: ele,
            ref: "na"
          })
        })
        parsedMssg[parsedMssg.length-1]["qtype"] = res.data.msg1[1]
        parsedMssg[parsedMssg.length-1]["options"] = res.data.msg1[2]
        parsedMssg[parsedMssg.length-1]["qid"] = res.data.msg1[3]
        parsedMssg[parsedMssg.length-1]["exhibit"] = res.data.msg1[4]
        parsedMssg[parsedMssg.length-1]["progress"] = res.data.msg1[5]

        let respMssg = {
          q: res.data.msg2,
          ref:"na"
        }
        let finalQues = [respMssg, ...selectedOptions]
        // let combinedMssg = [...mssg, respMssg, ...selectedOptions]
        // console.log(combinedMssg)
        // addToMessageBox(finalQues)
        sendToChatBox(finalQues,finalQues.length-1)
        //setTimeout(function(){addToMessageBox(finalQues)},1500)
        setTimeout(()=>{setNextQues(parsedMssg.reverse())},(finalQues.length)*1500)
        setProgress(parseInt(res.data.msg1[5], 10))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  function sendToChatBox(msglist,x){
    addToMessageBox([msglist[x]])
    if (x>0)
      setTimeout(() =>{sendToChatBox(msglist,x-1)},1500);
  }

  return (
    <div style={{height: "100%", background: "#0E6495"}}>
      <div style = {{ display:seeQues?"none":"flex", flexDirection: "column"}}>
     <GridProvider options={map} updateOptions={updateOptions} noOfRight={parseInt(messages.messages[0].qtype[3],10)}>
        <App1/>
     </GridProvider>
      </div>
      <div id="chat-form" style={{height:"8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", display:seeQues?"none":"flex", alignItems:"center", justifyItems:
    "center"}}>
      <button className='btn btn__secondary' onClick={onClickConfirm}>
        Confirm
      </button>
    </div>
    <div id="chat-form" style={{ display: seeQues?"flex":"none", height:"8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", alignItems:"center", justifyItems:
    "center"}}>
      <button className='btn btn__secondary' onClick = {() => {setSeeQues(false)}}>
        See Options
      </button>
    </div>  
    </div>
  )
}

export default Dnd
