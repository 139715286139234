import { Paper } from '@material-ui/core'
import styles from '../../styles/Interviews2.module.css'
import React, { useState, useEffect } from 'react'
import axios from '../../axios'
import Interview from './Interview'
import Loader from '../../utils/Loader'

export default function Interviews() {
  const [isLoading, setisLoading] = useState(true)

  const [histories, setHistories] = useState({})

  useEffect(() => {
    axios
      .get('/user-chats')
      .then((res) => {
        const data = res.data
        data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
        setHistories(data)
        setisLoading(false)
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [])

  // const details = [
  //   {
  //     caseName: 'Conversational AI Company',
  //     date: '10/19/2020, 1:26:13 PM',
  //     score: 0.75,
  //   },
  //   {
  //     caseName: 'Steel Tycoon',
  //     date: '10/20/2020, 1:48:05 PM',
  //     score: 1.15,
  //   },
  // ]
  return (
    <div style={{ paddingBottom: '200px' }}>
      <Paper elevation={10} className={styles.paper}>
        <h1>Interviews</h1>
        <div className={styles.tableHead}>
          <p>Case Name</p>
          <p>Date</p>
          <p>Score </p>
          <p className={styles.feedback}>Feedback</p>
          {/* <p style={{ paddingRight: '1.8rem' }}>Feedback</p> */}
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          histories &&
          histories.map((history) => (
            <Interview detail={history} key={history.id}></Interview>
          ))
        )}
        {}
      </Paper>
    </div>
  )
}
