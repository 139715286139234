import { Box, Container, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { NavAppBar, NavToolBar } from "../components/MaterialComponents";

export default function SuccessPage() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight={"100vh"}
      height={"100%"}
    >
      <NavAppBar style={{ backgroundColor: "#fff", boxShadow: "none" }}>
        <Container maxWidth="lg">
          <NavToolBar>
            <img
              style={{ display: "inline-block", width: "10vw", height: "auto" }}
              src="casey-logo-vcheng.png"
              alt="casey-logo-vcheng"
            />
          </NavToolBar>
        </Container>
      </NavAppBar>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Container maxWidth="md">
          <Typography
            align="center"
            style={{ marginBottom: 16, fontFamily: "inherit", fontSize: 24 }}
          >
            Thank you for using our Interactive Case Interview Simulator! The
            detailed feedback report will be emailed to you in the next few
            minutes.
          </Typography>
          <Typography
            align="center"
            style={{ fontFamily: "inherit", fontSize: 24 }}
          >
            Please <Link to="/cases">click here</Link> to return to the
            dashboard
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}
