import React from "react";
import styles from "../../styles/Interview.module.css";
import { Button } from "@material-ui/core";
import dateFormat from "dateformat";

const score = 0.75;
const linkref = (id) =>
  `https://interact.caseinterview.com/api/pdf-report/${id}`;
const date = (createdDate) => dateFormat(createdDate, "mm/dd/yyyy, h:MM:ss TT");

export default function Interview(props) {
  const { _id, createdDate, title } = props.detail;

  return (
    <div>
      <div className={styles.row}>
        <p className={styles.case}>{title}</p>
        <p className={styles.date}>{date(createdDate)}</p>
        <p className={styles.score}>{score}</p>
        <div className={styles.btnGroup}>
          <Button variant="contained" size="small" className={styles.idealBtn}>
            Ideal
          </Button>
          <Button
            href={linkref(_id)}
            target="_blank"
            variant="contained"
            size="small"
            className={styles.reportBtn}
          >
            Report
          </Button>
        </div>
      </div>
    </div>
  );
}
