import React from 'react'
import Chatbox from '../components/chatbox/Chatbox'

const ChatboxPage = () => {
	return (
		<div>
			<Chatbox />
		</div>
	)
}

export default ChatboxPage