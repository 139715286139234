import Validator from 'validator'
import isEmpty from './is-empty'

// SIGN UP VALIDATION
export const validateSignUpInput = (data) => {
  let errors = {}

  data.firstName = !isEmpty(data.firstName) ? data.firstName : ''
  data.lastName = !isEmpty(data.lastName) ? data.lastName : ''
  data.institution = !isEmpty(data.institution) ? data.institution : ''
  data.email = !isEmpty(data.email) ? data.email : ''
  data.password = !isEmpty(data.password) ? data.password : ''
  // data.phone = !isEmpty(data.phone) ? data.phone : ''

  if (Validator.isEmpty(data.firstName)) {
    errors.firstName = 'First name is required'
  }
  if (Validator.isEmpty(data.lastName)) {
    errors.lastName = 'Last name is required'
  }

  if (!Validator.isEmail(data.email)) {
    errors.email = 'Email is invalid'
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = 'Email field is required'
  }

  // if (!Validator.isLength(data.password, { min: 8 })) {
  //   errors.password = 'Password must be at least 8 characters'
  // }

  if (Validator.isEmpty(data.password)) {
    errors.password = 'Password field is required'
  }

  // if (!Validator.isMobilePhone(data.phone)) {
  //   errors.phone = 'Phone Number is Invalid'
  // }

  // if (Validator.isEmpty(data.phone, [0 - 9])) {
  //   errors.phone = 'Phone Number is required'
  // }

  if (Validator.isEmpty(data.institution)) {
    errors.institution = 'Institution is required'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

// SIGN IN VALIDATION
export const validateSignInInput = (data) => {
  let errors = {}

  data.email = !isEmpty(data.email) ? data.email : ''
  data.password = !isEmpty(data.password) ? data.password : ''

  if (!Validator.isEmail(data.email)) {
    errors.email = 'Email is invalid'
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = 'Email field is required'
  }

  if (Validator.isEmpty(data.password)) {
    errors.password = 'Password field is required'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

// FORGOT PASSWORD VALIDATION
export const validateEmail = (email) => {
  let errors = {}

  email = !isEmpty(email) ? email : ''

  if (!Validator.isEmail(email)) {
    errors.email = 'Email is invalid'
  }

  if (Validator.isEmpty(email)) {
    errors.email = 'Email field is required'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

// RESET FORM VALIDATION
export const validateResetInput = (data) => {
  let errors = {}

  data.firstName = !isEmpty(data.firstName) ? data.firstName : ''
  data.lastName = !isEmpty(data.lastName) ? data.lastName : ''
  data.institution = !isEmpty(data.institution) ? data.institution : ''
  // data.password = !isEmpty(data.password) ? data.password : ''
  // data.phone = !isEmpty(data.phone) ? data.phone : ''

  if (Validator.isEmpty(data.firstName)) {
    errors.firstName = 'First name is required'
  }
  if (Validator.isEmpty(data.lastName)) {
    errors.lastName = 'Last name is required'
  }

  // if (!Validator.isEmail(data.email)) {
  //   errors.email = 'Email is invalid'
  // }

  // if (Validator.isEmpty(data.email)) {
  //   errors.email = 'Email field is required'
  // }

  // if (!Validator.isLength(data.password, { min: 8 })) {
  //   errors.password = 'Password must be at least 8 characters'
  // }

  // if (Validator.isEmpty(data.password)) {
  //   errors.password = 'Password field is required'
  // }

  // if (!Validator.isMobilePhone(data.phone)) {
  //   errors.phone = 'Phone Number is Invalid'
  // }

  // if (Validator.isEmpty(data.phone, [0 - 9])) {
  //   errors.phone = 'Phone Number is required'
  // }

  // if (!Validator.isLength(data.phone, { min: 10, max: 10 })) {
  //   errors.phone = 'Password must be of 10 digits'
  // }

  if (Validator.isEmpty(data.institution)) {
    errors.institution = 'Institution is required'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export const validatePassword = (password) => {
  let errors = {}

  password = !isEmpty(password) ? password : ''

  if (Validator.isEmpty(password)) {
    errors.password = 'Password field is required'
  }

  if (!Validator.isLength(password, { min: 8 })) {
    errors.password = 'Password must be at least 8 characters'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}